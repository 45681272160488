import React, { useContext, useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { AppContext } from '../contexts/AppContext';
import { ChannelContext } from '../contexts/ChannelContext';
import { DocumentContext } from '../contexts/DocumentContext';
import { editTitleDocument, editContentDocument } from '../actions/document';

import DocumentDetail from '../components/Document/DocumentDetail';
import Blank from '../components/Layout/Blank';
import suggestionIcons from '../utils/suggestionIcons';
import { AttechmentContext } from '../contexts/AttechmentContext';
import DocumentDetailSceleton from '../components/Sceleton/DocumentDetialSceleton';

const DocumentDetailContainer = () => {
    const { authHandler } = useContext(AppContext);
    const { channel, setActifityType, loadSugestionMembers, loadSugestionRefs } = useContext(ChannelContext);
    const { document, isCreate, documentSlug, loadedDocument, reloadDocument } = useContext(DocumentContext)
    const { changeUploadHandler, loadingUpload, loadSugestionImages, uploadPaste } = useContext(AttechmentContext);

    const [documentItem, setDocumentItem] = useState({})
    const [is404, setIs404] = useState(false)

    const [focus, setFocus] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [formData, setFormData] = useState({})

    useEffect(() => {
        let found = false
        document.forEach(item => {
            if (documentSlug === item.slug) {
                setDocumentItem(item)
                setIs404(false)
                found = true
                
                setActifityType({
                    singular: 'wiki',
                    plural: 'wiki',
                    id: item.id,
                    version: item.version,
                })
            }
        });
        if (!found) {
            setIs404(true)
        }
    }, [documentSlug, document, setActifityType, loadedDocument])

    const setFocusHandler = e => {
        e.preventDefault();
        setFocus(e.currentTarget.dataset.key)
    }

    const changeTitleHandler = e => {
        setFormData({ ...formData, title: e.target.value})
    }

    const submitCallback = useCallback((success, data) => {
        if (success) {
            toast.success('Success change ' + focus + ' document.')
            reloadDocument(() => {
                setFocus(false)
                setFormData({})
            })
        } else {
            toast.error(data.error.response.data._error_message)
        }
        setLoadingSubmit(false)
    }, [reloadDocument, focus])

    const submitTitleHandler = e => {
        e.preventDefault();
        if (!loadingSubmit) {
            setLoadingSubmit(true)
            editTitleDocument(authHandler, {
                id: documentItem.wikiLinkId
            }, {
                title: formData.title
            }, submitCallback)
        }
    }

    const changeContentHandler = value => {
        setFormData({ ...formData, content: value })
    }

    const submitContentHandler = e => {
        e.preventDefault();
        if (!loadingSubmit) {
            setLoadingSubmit(true)
            editContentDocument(authHandler, {
                id: documentItem.id
            }, {
                content: formData.content,
                version: documentItem.version
            }, submitCallback)
        }
    }

    const handler = {
        setFocusHandler,
        changeTitleHandler,
        submitTitleHandler,
        changeContentHandler,
        submitContentHandler,
    }

    const permission = {
        canEditTitle: channel.i_am_admin
    }

    const loadSuggestions = (text, triggeredBy) => {
        if (triggeredBy === '@') {
            return loadSugestionMembers(text)
        } else if (triggeredBy === '#') {
            return loadSugestionRefs(text)
        } else if (triggeredBy === ':') {
            return suggestionIcons(text)
        } else if (triggeredBy === '!') {
            return loadSugestionImages(text)
        }
    }

    if (!documentSlug || (is404 && documentSlug === 'home')) {
        return <div className={`flex-full${isCreate ? ' d-none' : ''}`}>
            <Blank />
        </div>
    }

    if (!loadedDocument) {
        return <div className={`flex-full${isCreate ? ' d-none' : ''}`}>
            <DocumentDetailSceleton />
        </div>
    }

    if (is404) {
        return <div className={`flex-full${isCreate ? ' d-none' : ''}`}>
            <Blank label="404 - Document Not Found" />
        </div>
    }

    return <DocumentDetail 
        documentItem={documentItem}
        permission={permission}
        loadSuggestions={loadSuggestions}
        focus={focus}
        loadingSubmit={loadingSubmit}
        formData={formData}
        handler={handler}
        changeUploadHandler={changeUploadHandler}
        loadingUpload={loadingUpload}
        uploadPaste={uploadPaste}
        isCreate={isCreate} />
}
 
export default DocumentDetailContainer;