export const navItems = [
    {
        key: 'discussions',
        path: '/discussions',
        label: 'Discussions',
    },
    {
        key: 'documents',
        path: '/documents',
        label: 'Documents',
    },
    // {
    //     key: 'settings',
    //     path: '/settings',
    //     label: 'Settings',
    // },
]