import React, { useContext, useState, useEffect } from 'react';

import { ChannelContext } from '../contexts/ChannelContext';
import { ActifityContext } from '../contexts/ActifityContext';
import { AttechmentContext } from '../contexts/AttechmentContext';
import { arrayColumn, arrSortInDesc } from '../utils/helper';

import Actifity from '../components/Actifity';

const ActifityContainer = () => {
    const { channel } = useContext(ChannelContext);
    const { actifity, reloadActifity } = useContext(ActifityContext);
    const { attecment } = useContext(AttechmentContext)
    
    const [hiddenAttachment, setHiddenAttachment] = useState([])
    const [loaded, setLoaded] = useState(false)
    
    useEffect(() => {
        reloadActifity(() => {
            setLoaded(true)
        })
    }, [reloadActifity])
    
    useEffect(() => {
        setHiddenAttachment(attecment.filter(i => i.from_comment).map(i => i.id))
    }, [attecment])

    return <Actifity 
        loaded={loaded}
        hiddenAttachment={hiddenAttachment}
        issueStatus={arrayColumn(channel.issue_statuses, 'id')}
        issueTypes={arrayColumn(channel.issue_types, 'id')} 
        actifity={arrSortInDesc(actifity)} />
}
 
export default ActifityContainer;