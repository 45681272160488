import React from 'react';

const Tag = ({tagLists}) => {
    return (
        <ul className="list-inline mb-0">
            {tagLists.map(tag => (
                <li className="list-inline-item mr-1" key={`${tag[1]}${tag[0]}`}>
                    <small className="tag" style={{ borderColor: tag[1], color: tag[1] }}>{tag[0]}</small>
                </li>
            ))}
        </ul>
    );
}
 
export default Tag;