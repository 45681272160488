import React from 'react';

const DocumentDetailSceleton = () => {
    return (
        <div className="row no-gutters flex-full">
            <div className="col-8 mx-auto py-4">
                <h2 className="mb-3"><div className="sceleton">lorem</div></h2>
                <div className="d-flex justify-content-between mb-3 text-muted">
                    <small>
                        <div className="sceleton mr-2">Date Created Document</div>
                    </small>
                    <small>
                        <div className="sceleton">Date Modified Document</div>
                    </small>
                </div>
                <div>
                    <div className="sceleton mb-1">Lorem</div>
                    <div className="sceleton mb-1">Lorem</div>
                    <div className="d-flex">
                        <div className="sceleton mb-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et, atque!</div>
                    </div>
                    <div className="sceleton mb-1">Lorem</div>
                    <div className="sceleton mb-1">Lorem</div>
                    <div className="d-flex">
                        <div className="sceleton mb-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et, atque!</div>
                    </div>
                </div>
                <div className="d-flex justify-content-between text-muted mb-2">
                    <small>
                        <div className="d-flex justify-content-start">
                            <div className="sceleton mr-3">Add Attachment</div>
                            <div className="sceleton mr-3">Button</div>
                        </div>
                    </small>
                </div>
            </div>
        </div>
    );
}
 
export default DocumentDetailSceleton;