import React from 'react';

import MdEditor from '../Layout/MdEditor';
import Media from '../Layout/Media';

const CommentCreate = ({ userData, loadingForm, comment, setCommnet, loadSuggestions, handlerSubmit, uploadPaste}) => {
    return (
        <Media userData={userData}>
            <form 
                onSubmit={handlerSubmit}
                className="card rounded-xl border-0 comment" >
                <MdEditor
                    setValue={setCommnet}
                    loadSuggestions={loadSuggestions}
                    value={comment} 
                    uploadPaste={uploadPaste} 
                    placeholder="Add your reply ..."/>
                <div className="card-footer text-right">
                    {loadingForm ? (
                        <button className="btn btn-sm btn-primary rounded-pill" disabled>Loading...</button>
                    ) : (
                        <button className="btn btn-sm btn-primary rounded-pill">Send</button>
                    )}
                </div>
            </form>
        </Media>
    );
}
 
export default CommentCreate;