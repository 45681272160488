import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DiscussionFilter = ({ issue_types, filter, typeData, handler }) => {
    return (
        <div className="card-header d-flex justify-content-between align-items-center bg-light py-2">
            <ul className="list-inline mb-0">
                <li className="list-inline-item mr-3">
                    <span
                        onClick={handler.filterType} 
                        data-value="0" 
                        className="clickable">
                        <span className="badge badge-primary mr-2" >{typeData[0]}</span>
                        <small className={filter.type.length === 0 ? "font-weight-bold" : ""}>All</small>
                    </span>
                </li>
                {issue_types.map((type, i) => {
                    // if( type.name.startsWith("__") ) return;

                    return typeData[type.id] !== undefined && (
                        <li className="list-inline-item mr-3" key={i}>
                            <span onClick={handler.filterType} 
                                data-value={type.id} 
                                // style={{ color: type.color }} 
                                className="clickable">
                                <span className="badge badge-success mr-2" style={{ background: type.color }}>{typeData[type.id]}</span>
                                <small className={filter.type.includes(type.id) ? "font-weight-bold" : "text-muted"}>{type.name}</small>
                            </span>
                        </li>
                    )
                    })}
            </ul>
            <div>
                <a href="/" className="text-muted" onClick={handler.filterShowClosed} title={filter.showClosed ? "Sembunyikan diskusi berakhir" : "Tampilkan diskusi berakhir"}>
                    <FontAwesomeIcon icon={filter.showClosed ? "toggle-on" : "toggle-off"} fixedWidth />
                </a>
            </div>
        </div>
    );
}
 
export default DiscussionFilter;