const notificationReducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_NOTIFICATION':
            return action.data
        case 'REMOVE_NOTIFICATION':
            return state.filter(item => item.id !== action.id)
        default:
            return state;
    }
}

export default notificationReducer;