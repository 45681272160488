import React, { useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppContext } from '../contexts/AppContext';
import { ChannelContext } from '../contexts/ChannelContext';
import { DocumentContext } from '../contexts/DocumentContext';
import { createDocument } from '../actions/document';
import suggestionIcons from '../utils/suggestionIcons';

import DocumentCreate from '../components/Document/DocumentCreate';

const DocumentCreateContainer = () => {
    const history = useHistory()
    const { authHandler } = useContext(AppContext)
    const { channel, loadSugestionMembers, loadSugestionRefs } = useContext(ChannelContext)
    const { isCreate, setIsCreate, reloadDocument } = useContext(DocumentContext)

    const [loadinForm, setLoadinForm] = useState(false)
    const initForm = {
        title: '',
        content: '',
    }
    const [formData, setFormData] = useState(initForm)

    const closeHandler = () => {
        setIsCreate(false)
    }

    const handlerChangeTitle = e => {
        setFormData({ ...formData, title: e.target.value })
    }
    
    const handlerChangeContent = (value) => {
        setFormData({ ...formData, content: value })
    }

    const submitCallback = useCallback((success, data) => {
        if(success) {
            reloadDocument(() => {
                setFormData(initForm)
                history.push('/channels/' + channel.slug +'/documents/' + data.slug)
                toast.success('Success create document.')
            })
        } else {
            console.log(data)
            toast.error("Filed create document.")
        }
        setLoadinForm(false)
    }, [initForm, history, reloadDocument, channel.slug])

    const submitHandler = (e) => {
        e.preventDefault()
        setLoadinForm(true)
        createDocument(authHandler, { ...formData, id: channel.id}, submitCallback)
    }

    const handler = {
        closeHandler, 
        submitHandler, 
        handlerChangeTitle,
        handlerChangeContent,
    }

    const loadSuggestions = (text, triggeredBy) => {
        if (triggeredBy === '@') {
            return loadSugestionMembers(text)
        } else if (triggeredBy === '#') {
            return loadSugestionRefs(text)
        } else if (triggeredBy === ':') {
            return suggestionIcons(text)
        }
    }

    return <DocumentCreate 
        handler={handler}
        loadSuggestions={loadSuggestions}
        formData={formData}
        loadinForm={loadinForm}
        isCreate={isCreate} />
}
 
export default DocumentCreateContainer;