import React, { useEffect, useContext, useState } from 'react';

import Attachment from '../components/Attachment';
import { AttechmentContext } from '../contexts/AttechmentContext';

const AttachmentContrainer = () => {

    const { attecment, reloadAttechment, displayFormCommnet } = useContext(AttechmentContext)

    const [displayAttechment, setDisplayAttechment] = useState([])

    useEffect(() => {
        reloadAttechment(() => {})
    }, [reloadAttechment])

    useEffect(() => {
        setDisplayAttechment(attecment.filter(item => !item.from_comment || (item.from_comment && displayFormCommnet)))
    }, [attecment, displayFormCommnet])

    return <Attachment attecment={displayAttechment}/>
}

export default AttachmentContrainer;