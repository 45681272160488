import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter, Link } from 'react-router-dom';

import { beebucketUrl } from '../../../configs/app';

import { ChannelContext } from '../../../contexts/ChannelContext';
import { channelNavs } from '../../../configs/navs';

import NavbarItem from './NavbarItem';

const LeftNavChannel = ({ location }) => {

    const { channel } = useContext(ChannelContext);
    const [navItems, setNavItems] = useState([])

    useEffect(() => {
        setNavItems(channelNavs.navItems.map((navItem) => {
            return { ...navItem, path: '/channels/' + channel.slug + navItem.path }
        }))
    }, [channel.slug])
    
    return (
        <>
            <Link className="navbar-brand" to={`/channels/${channel.slug}`}>
                <span>{channel.name}</span>
            </Link>

            <button className="navbar-toggler mr-auto" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <FontAwesomeIcon icon="th" size="sm" fixedWidth />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    {navItems.map(navItem => <NavbarItem {...navItem} routePath={location.pathname} />)}
                    
                    <li className="nav-item">
                        <a href={beebucketUrl + (channel.id !== undefined ? 'project/' + channel.slug : '')} className="nav-link" 
                        title="Jump to Beebucket" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon="external-link-alt" className="mr-1" fixedWidth size="sm" />
                            Tasks
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
}
 
export default withRouter(LeftNavChannel);