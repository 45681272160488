import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { DocumentContext } from '../contexts/DocumentContext';
import Document from '../components/Document';

const DocumentContainer = ({ match }) => {
    const { setIsCreate, setDocumentSlug } = useContext(DocumentContext)

    useEffect(() => {
        setIsCreate(false)
        setDocumentSlug(match.params.documentSlug ? match.params.documentSlug : 'home')            
    }, [match.params.documentSlug, setIsCreate, setDocumentSlug])
    return <Document />
}
 
export default withRouter(DocumentContainer);