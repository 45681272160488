import React from 'react';
import Navbar from '../Layout/Navbar';

const ChannelSceleton = () => {
    let leftNav = <>
        <span className="navbar-brand">
            <div className="sceleton">Channel Name</div>
        </span>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
                {[1, 2].map((i) =>
                    <li key={i} className="nav-item">
                        <span className="nav-link">
                            <div className="sceleton">MenuChannel</div>
                        </span>
                    </li>
                )}
            </ul>
        </div>
    </>
    let rightNav = <>
    </>
    return (
        <Navbar leftNav={leftNav} rightNav={rightNav}/>
    );
}
 
export default ChannelSceleton;