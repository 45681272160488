import axios from 'axios';

let sourceGetChannel = null;
let sourceLoadChannel = null;

export const getChannel = (authHandler, params, callback) => {
    if(sourceGetChannel !== null) {
        sourceGetChannel.cancel()
    }
    sourceGetChannel = axios.CancelToken.source()
    axios
        .get('projects/by_slug', {
            params: params,
            headers: authHandler.getHeaders,
            cancelToken: sourceGetChannel.token
        })
        .then(response => {
            sourceGetChannel = null
            callback(true, response.data, { type: 'UPDATE_CHANNEL', data: response.data })
        })
        .catch(error => {
            callback(false, { error })
        });
}

export const loadChannel = (authHandler, params, callback) => {
    if(sourceLoadChannel !== null) {
        sourceLoadChannel.cancel()
    }
    sourceLoadChannel = axios.CancelToken.source()
    axios
        .get('projects', { 
            params: params,
            headers: authHandler.getHeaders,
            cancelToken: sourceLoadChannel.token
        })
        .then(response => {
            sourceLoadChannel = null
            callback(true, { type: 'LOAD_CHANNEL', data: response.data })
        })
        .catch(error => {
            callback(false, { error })
        });
}
