import React from 'react';

const SimpleLayout = ({ children }) => {
    return (
        <div className="body bg-light flex-full d-flex flex-column h-100">
            <div className="flex-full">{children}</div>
        </div>
    )
}

export default SimpleLayout;