export const toastConfig = {
    autoClose: 3000
}

export const colors = {
    Red: "#F44336",
    Pink: "#E91E63",
    Purple: "#9C27B0",
    // "Deep Purple": "#673AB7",
    Indigo: "#3F51B5",
    Blue: "#2196F3",
    // "Light Blue": "#03A9F4",
    Cyan: "#00BCD4",
    // Teal: "#009688",
    Green: "#4CAF50",
    // "Light Green": "#8BC34A",
    // Lime: "#CDDC39",
    // Yellow: "#FFEB3B",
    Yellow: "#ffd43b",
    // Amber: "#FFC107",
    // Orange: "#FF9800",
    Orange: "#F57C00", // 600
    "Deep Orange": "#FF5722",
    Brown: "#795548",
    // Gray: "#9E9E9E",
    "Blue Gray": "#607D8B"
        // "No Color": "transparent"
}

export const beebucketUrl = "https://taman.altra.id/"