import React, { createContext, useReducer, useState, useCallback, useContext } from 'react'

import documentReducer from '../reducers/documentReducer';
import { loadDocument } from '../actions/document';
import { AppContext } from './AppContext';
import { ChannelContext } from './ChannelContext';

export const DocumentContext = createContext();

const DocumentContextProvider = ({ children }) => {

    const { authHandler } = useContext(AppContext);
    const { channel } = useContext(ChannelContext);

    const [document, documentDispatch] = useReducer(documentReducer, [])
    const [isCreate, setIsCreate] = useState(false)
    const [documentSlug, setDocumentSlug] = useState(false)
    const [loadedDocument, setLoadedDocument] = useState(false)

    const reloadDocument = useCallback((callback) => {
        loadDocument(authHandler, {
            project: channel.id,
        }, (success, data) => {
            if (success) {
                documentDispatch(data)
                if (documentSlug) {
                    setLoadedDocument(true)
                }
            }
            callback(success, data)
        })
    }, [authHandler, channel.id, documentSlug])

    return (
        <DocumentContext.Provider value={{ 
            document, 
            reloadDocument, 
            documentSlug, setDocumentSlug, 
            loadedDocument, 
            isCreate, setIsCreate }}>
            {children}
        </DocumentContext.Provider>
    );
}

export default DocumentContextProvider;