import React from 'react';

import DiscussionListContainer from '../../container/DiscussionListContainer';
import DiscussionDetailContainer from '../../container/DiscussionDetailContainer';
import DiscussionDetailContextProvider from '../../contexts/DiscussionDetailContext';
import ActifityContextProvider from '../../contexts/ActifityContext';
import DiscussionCreateContainer from '../../container/DiscussionCreateContainer';
import AttechmentContextProvider from '../../contexts/AttechmentContext';

const Discussion = () => {
    return (
        <div className="row no-gutters h-100">
            <div className="col border-right h-100" style={{maxWidth: 500}}>
                <DiscussionListContainer />
            </div>
            <div className="col d-flex flex-column h-100">
                <DiscussionCreateContainer />
                <ActifityContextProvider>
                    <AttechmentContextProvider>
                        <DiscussionDetailContextProvider>
                            <DiscussionDetailContainer />
                        </DiscussionDetailContextProvider>
                    </AttechmentContextProvider>
                </ActifityContextProvider>
            </div>
        </div>
    );
}

export default Discussion;