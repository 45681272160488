import React, { useContext, useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';

import { AppContext } from '../contexts/AppContext';
import { ChannelContext } from '../contexts/ChannelContext';
import { DiscussionContext } from '../contexts/DiscussionContext';
import { DiscussionDetailContext } from '../contexts/DiscussionDetailContext';
import { AttechmentContext } from '../contexts/AttechmentContext';
import { inputActifity } from '../actions/actifity';
import { arrayColumn } from '../utils/helper';
import suggestionIcons from '../utils/suggestionIcons';

import Blank from '../components/Layout/Blank';
import DiscussionDetail from '../components/Discussion/DiscussionDetail';
import DiscussionDetailSceleton from '../components/Sceleton/DiscussionDetailSceleton';

const DiscussionDetailContainer = () => {

    const { authHandler } = useContext(AppContext);
    const { channel, actifityType, setActifityType, loadSugestionMembers, loadSugestionRefs, reloadActifity } = useContext(ChannelContext);
    const { discussion, isCreate, discussionRef, reloadDiscussion } = useContext(DiscussionContext);
    const { discussionItem, reloadDiscussionDetail } = useContext(DiscussionDetailContext);
    const { changeUploadHandler, loadingUpload, loadSugestionImages, uploadPaste, displayFormCommnet, toggleDisplayFormCommnet, cleanAttachment } = useContext(AttechmentContext);

    const [loaded, setLoaded] = useState(false)
    const [is404, setIs404] = useState(false);

    const [loadingChange, setLoadingChange] = useState(false);
    const [tempChange, setTempChange] = useState({});

    const loadCallback = useCallback((success) => {
        setTempChange({})
        if ( success ) {
            setIs404(false)
        } else {
            setIs404(true)
        }
        setLoaded(true)
    }, [])

    useEffect(() => {
        if (discussionRef) {
            setLoaded(false)
            reloadDiscussionDetail(loadCallback)
        }
    }, [discussionRef, reloadDiscussionDetail, loadCallback])

    useEffect(() => {
        if (actifityType.version !== undefined && discussionItem.version !== undefined && actifityType.version !== discussionItem.version) {
            reloadDiscussionDetail(loadCallback)
        }
    }, [actifityType, discussionItem, reloadDiscussionDetail, loadCallback])

    useEffect(() => {
        if (loaded) {
            discussion.forEach(item => {
                if (item.id === discussionItem.id && new Date(item.modified_date) > new Date(discussionItem.modified_date)) {
                    reloadDiscussionDetail(() => {})
                }
            });
        }
    }, [loaded, discussion, discussionItem, reloadDiscussionDetail])

    useEffect(() => {
        if (discussionRef) {
            cleanAttachment()
        }
    }, [discussionRef, cleanAttachment])

    useEffect(() => {
        setActifityType({ 
            singular: 'issue', 
            plural: 'issues', 
            id: discussionItem.id, 
            version: discussionItem.version,
        })
    }, [discussionItem.id, setActifityType, discussionItem.version])

    /* 
    | action edit handler
    */
    const setFocus = e => {
        e.preventDefault();
        if (!loadingChange) {
            setTempChange({ ...tempChange, focus: e.currentTarget.dataset.changeKey})
        }
    }

    const changeValueData = e => {
        setTempChange({ ...tempChange, [e.target.name]: e.target.value})
    }

    const handlerChangeDescription = value => {
        setTempChange({ ...tempChange, description: value })
    }

    const changeDataCallback = useCallback((success, data) => {
        if (success) {
            toast.success('Success change ' + (tempChange.focus ? tempChange.focus : 'tags') + '.')
            setTempChange({})
            reloadDiscussionDetail(() => {})
            reloadActifity(() => {})
            reloadDiscussion(() => {})
        } else {
            setLoadingChange(false)
            // toast.error(data.error.response.data._error_message)
        }
        setLoadingChange(false)
    }, [tempChange.focus, reloadDiscussionDetail, reloadActifity, reloadDiscussion])
    
    const changeDataHandler = e => {
        e.preventDefault();
        if (!loadingChange) {
            setLoadingChange(true)
            setTempChange({ ...tempChange, focus: e.currentTarget.dataset.changeKey})
            inputActifity(actifityType, authHandler, {
                [e.currentTarget.dataset.changeKey]: e.currentTarget.dataset.changeValue ?? null,
            }, changeDataCallback)
        }
    }
    
    const addSubscriberHandler = e => {
        e.preventDefault();
        if (!loadingChange) {
            setLoadingChange(true)
            inputActifity(actifityType, authHandler, {
                watchers: discussionItem.watchers.filter(i => i !== Number(e.currentTarget.dataset.value)),
            }, changeDataCallback)
        }
    }
    
    const removeSubscriberHandler = e => {
        e.preventDefault();
        if (!loadingChange) {
            setLoadingChange(true)
            inputActifity(actifityType, authHandler, {
                watchers: [...discussionItem.watchers, Number(e.currentTarget.dataset.value)],
            }, changeDataCallback)
        }
    }

    const addAllSubscriberHandler = e => {
        e.preventDefault();
        if (!loadingChange) {
            setLoadingChange(true)
            inputActifity(actifityType, authHandler, {
                watchers: channel.members.map(member => member.id),
            }, changeDataCallback)
        }
    }
    
    const addTagHandler = e => {
        e.preventDefault();
        if (!loadingChange) {
            setLoadingChange(true)
            inputActifity(actifityType, authHandler, {
                tags: [...discussionItem.tags, [e.currentTarget.dataset.changeValueName, e.currentTarget.dataset.changeValueColor]],
            }, changeDataCallback)
        }
    }
    
    const removeTagHandler = e => {
        e.preventDefault();
        if (!loadingChange) {
            setLoadingChange(true)
            discussionItem.tags.splice(e.currentTarget.dataset.tagKey, 1)
            inputActifity(actifityType, authHandler, {
                tags: discussionItem.tags,
            }, changeDataCallback)
        }
    }
    /*
    | ! action edit handler
    */

    if (!discussionRef) {
        return <div className={`flex-full${isCreate ? ' d-none' : ''}`}>
            <Blank />
        </div>
    }

    const loadSuggestions = (text, triggeredBy) => {
        if (triggeredBy === '@') {
            return loadSugestionMembers(text)
        } else if (triggeredBy === '#') {
            return loadSugestionRefs(text)
        } else if (triggeredBy === ':') {
            return suggestionIcons(text)
        } else if (triggeredBy === '!') {
            return loadSugestionImages(text)
        }
    }

    if (!loaded) {
        return <div className={`flex-full${isCreate ? ' d-none' : ''}`}>
            <DiscussionDetailSceleton />
        </div>
    }

    if (is404) {
        return <div className={`flex-full${isCreate ? ' d-none' : ''}`}>
            <Blank label="404 - Discussion Not Found" />
        </div>
    }

    return <DiscussionDetail 
        issue_statuses={channel.issue_statuses}
        tags_colors={channel.tags_colors}
        issue_types={arrayColumn(channel.issue_types, 'id')}
        tempChange={tempChange}
        setFocus={setFocus}
        changeValueData={changeValueData}
        addTagHandler={addTagHandler}
        removeTagHandler={removeTagHandler}
        loadingChange={loadingChange}
        changeDataHandler={changeDataHandler}
        loadSuggestions={loadSuggestions}
        handlerChangeDescription={handlerChangeDescription}
        changeUploadHandler={changeUploadHandler}
        loadingUpload={loadingUpload}
        uploadPaste={uploadPaste}
        discussionItem={discussionItem}
        displayCommnetAttechment={displayFormCommnet}
        toggleDisplayCommnetAttechment={toggleDisplayFormCommnet}
        members={channel.members}
        addSubscriberHandler={addSubscriberHandler}
        removeSubscriberHandler={removeSubscriberHandler}
        addAllSubscriberHandler={addAllSubscriberHandler}
        isCreate={isCreate} />
}

export default DiscussionDetailContainer;