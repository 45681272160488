export const getInitials = (label) => {
    let initials = label.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
}

export const arrayColumn = (arr, key, value) => {
    let temp = [] 
    arr.forEach((item, i) => {
        temp[typeof key === 'string' ? item[key] : i] = typeof value === 'string' ? item[value] : item
    })
    return temp
}

export const arrSortInDesc = (arr) => {
    if (arr <= 1) 
        return arr
    let temp = []
    for (let i = arr.length - 1; i >= 0; i--) {
        temp.push(arr[i])
    }
    return temp
}

export const limitStr = (str, limit = 40) => {
    return str.length > limit ? str.substr(0, limit) + '...' : str
}

export const getToken = () => {
    let u = new Uint32Array(1);
    window.crypto.getRandomValues(u);
    let str = u[0].toString(16).toUpperCase();
    let d = new Date();
    let n = d.getTime();
    return '00000000'.slice(str.length) + str + n;
}