import React, { useContext, useState, useEffect, useCallback } from 'react';

import { ChannelContext } from '../contexts/ChannelContext';
import { DiscussionContext } from '../contexts/DiscussionContext';
import { arrayColumn } from '../utils/helper';

import DiscussionList from '../components/Discussion/DiscussionList';
import DiscussionListSceleton from '../components/Sceleton/DiscussionListSceleton';
import { useWebsocket } from '../contexts/WebsocketContext';

const DiscussionListContainer = () => {
    const { hasOpen, subscibeHandler, unsubscibeHandler } = useWebsocket()

    const { channel } = useContext(ChannelContext);
    const { discussion, reloadDiscussion, setIsCreate, discussionRef, discussionFilter } = useContext(DiscussionContext);

    const [loaded, setLoaded] = useState(false)

    const reloadCallback = useCallback(() => {
        setLoaded(true);
    }, [])

    useEffect(() => {
        reloadDiscussion(reloadCallback)
    }, [reloadCallback, reloadDiscussion])

    const listenChangeHandler = useCallback((message) => {
        reloadDiscussion(() => {})
        // console.log(message)
    }, [reloadDiscussion])

    useEffect(() => {
        let route = 'changes.project.' + channel.id + '.issues'
        if (loaded && hasOpen) {
            subscibeHandler(route, listenChangeHandler)
        }
        return () => {
            unsubscibeHandler(route)
        }
    }, [loaded, hasOpen, subscibeHandler, listenChangeHandler, channel.id, unsubscibeHandler])

    if (!loaded) {
        return <DiscussionListSceleton />
    }

    let fiteredDiscussion = discussion.filter(item => {
        return (discussionFilter.showClosed || (!discussionFilter.showClosed && !item.is_closed)) && 
            (discussionFilter.type.length === 0 || discussionFilter.type.includes(item.type))
    })

    return <DiscussionList 
        discussion={fiteredDiscussion}
        setIsCreate={setIsCreate}
        issueTypes={arrayColumn(channel.issue_types, 'id')}
        discussionRef={discussionRef} />
}

export default DiscussionListContainer;