export const navItems = [
    // {
    //     path: '/my_inbox',
    //     icon: 'inbox',
    //     label: 'Inbox'
    // },
    // {
    //     path: '/my_task',
    //     icon: 'tasks',
    //     label: 'My Task'
    // },
    // {
    //     path: '/dashboard',
    //     icon: 'home',
    //     label: 'Dashboard'
    // },
    {
        type: 'divider',
        label: 'CHANNELS',
    },
]

export const loadingNavItem = {
    type: 'divider',
    label: 'Loading ...'
}