import React, { useContext, useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppContext } from '../contexts/AppContext';
import { login } from '../actions/auth'

import Login from '../components/Login';

const LoginContainer = () => {
    const history = useHistory()

    const { auth, authDispatch } = useContext(AppContext);

    const [loadingForm, setLoadingForm] = useState(false)
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const handlerChangeInput = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const submitCallback = (success, data) => {
        setLoadingForm(false)
        if ( success ) {
            toast.success('Success login.')
            authDispatch(data)
        } else {
            toast.error(data.error.response.data._error_message)
        }
    }
    
    const handlerSubmit = (e) => {
        e.preventDefault();
        if (!loadingForm) {
            setLoadingForm(true)
            login(formData, submitCallback)
        }
    }

    useEffect(() => {
        if (Object.keys(auth).length !== 0) {
            history.push('/')
        }
    }, [auth, history]);

    if (Object.keys(auth).length !== 0) {
        return <Redirect to="/" />
    }

    return <Login {...{ loadingForm, handlerChangeInput, handlerSubmit }}/>
}

export default LoginContainer;