import axios from 'axios';

let sourceGetDiscussion = null;
let sourceLoadDiscussion = null;

export const getDiscussion = (authHandler, params, callback) => {
    if(sourceGetDiscussion !== null) {
        sourceGetDiscussion.cancel()
    }
    sourceGetDiscussion = axios.CancelToken.source()
    axios
        .get('issues/by_ref', {
            params,
            headers: authHandler.getHeaders,
            cancelToken: sourceGetDiscussion.token
        })
        .then(response => {
            sourceGetDiscussion = null
            callback(true, response.data, { type: 'UPDATE_DISCUSSION', data: response.data })
        })
        .catch(error => {
            callback(false, { error })
        });
}

export const loadDiscussion = (authHandler, params, callback) => {
    if(sourceLoadDiscussion !== null) {
        sourceLoadDiscussion.cancel()
    }
    sourceLoadDiscussion = axios.CancelToken.source()
    axios
        .get('issues', { 
            params: params,
            headers: authHandler.getHeaders,
            cancelToken: sourceLoadDiscussion.token
        })
        .then(response => {
            sourceLoadDiscussion = null
            callback(true, { type: 'LOAD_DISCUSSION', data: response.data })
        })
        .catch(error => {
            callback(false, { error })
        });
}

export const createDiscussion = (authHandler, payload, callback) => {
    axios
        .post('issues', payload, {
            headers: authHandler.getHeaders
        })
        .then(response => {
            callback(true, response.data)
        })
        .catch(error => {
            callback(false, { error })
        });
}