const attechmentReducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_ATTECHMENT':
            return action.data
        case 'INSERT_ATTECHMENT':
            return [...state, action.data]
        case 'RESET_ATTECHMENT':
            return []
        default:
            return state;
    }
}

export default attechmentReducer;