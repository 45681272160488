import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { MainContext } from '../../../contexts/MainContext';
import SidenavItem from './SidenavItem';

const Sidenav = ({ navItems, routePath, authData }) => {
    const { minimized } = useContext(MainContext);
    return (
        <nav className={`sidenav navbar-dark d-flex flex-column${minimized ? ' minimized' : ''}`}>
            <div className="sidenav-header">
                <div className="sidenav-brand">
                </div>
                <div className="sidenav-avatar">
                    <div className="avatar avatar-lg bg-white text-secondary">
                        {authData.photo ? (
                            <img 
                                src={authData.photo} 
                                alt={authData.full_name_display} 
                                title={authData.full_name_display} />
                        ) : (
                            <FontAwesomeIcon icon="user-astronaut" size="3x"/>
                        )}
                    </div>
                    <div className="sidenav-avatar-text">
                        <span>{authData.full_name_display}</span>
                    </div>
                </div>
            </div>
            <ul className="navbar-nav scroll-onhover w-100" style={{whiteSpace: "nowrap"}}>
                {navItems.map((navItem, i) => <SidenavItem key={i} {...navItem} routePath={routePath} />)}
            </ul>
        </nav>
    );
}

export default Sidenav;