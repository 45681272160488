import React, { createContext, useState, useCallback, useContext } from 'react'
import { AppContext } from './AppContext';
import axios from 'axios';

export const ChannelContext = createContext();

const ChannelContextProvider = ({ children }) => {
    const { authHandler } = useContext(AppContext);

    const [channel, setChannel] = useState({})
    const [actifityType, setActifityType] = useState({})
    
    const loadSugestionMembers = useCallback((text) => {
        return new Promise((accept) => {
            setTimeout(() => {
                const suggestions = channel.members.map(item => {
                    return {
                        preview: item.full_name_display,
                        value: '@' + item.username
                    }
                }).filter(i => i.preview.toLowerCase().includes(text.toLowerCase()));
                accept(suggestions);
            }, 250);
        });
    }, [channel.members])
    
    const loadSugestionRefs = useCallback((text) => {
        return new Promise((accept) => {
            const fetchData = async (text) => {
                try {
                    const result = await axios
                        .get('search', {
                            params: {
                                get_all: false,
                                project: channel.id,
                                text: text,
                            },
                            headers: authHandler.getHeaders
                        })
                    accept(result.data.issues.map(item => {
                        let label = '#' + item.ref + ' - ' + item.subject
                        let link = '/channels/' + channel.slug + '/discussions/' + item.ref
                        return {
                            preview: label,
                            value: '[' + label + '](' + link + ')'
                        }
                    }))
                } catch (error) {
                    accept([]);
                }
            }
            fetchData(text);
        });
    }, [authHandler.getHeaders, channel.id, channel.slug])

    return (
        <ChannelContext.Provider value={{ channel, setChannel, actifityType, setActifityType, loadSugestionMembers, loadSugestionRefs }}>
            {children}
        </ChannelContext.Provider>
    );
}

export default ChannelContextProvider;