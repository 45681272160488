import React from 'react';
import SimpleLayout from './SimpleLayout';

const Blank = ({label}) => {
    return (
        <SimpleLayout>
            <div className="py-3 px-4 text-muted">{label !== undefined && label}</div>
        </SimpleLayout>
    );
}
 
export default Blank;