import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DocumentItem from './DocumentItem';

const DocumentList = ({ document, documentSlug, setIsCreate }) => {
    return (
        <div className="card border-0 h-100 d-flex flex-column">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h6 className="card-title">Semua Document</h6>
                <div>
                    <a
                        href="/"
                        onClick={e => {
                            e.preventDefault()
                            setIsCreate(true)
                        }}
                        className="text-primary">
                        <FontAwesomeIcon icon="plus" fixedWidth />
                    </a>
                </div>
            </div>
            <div className="flex-full">
                <ul className="list-group list-group-flush list-hover">
                    {document.map((item, i) => (
                        <DocumentItem 
                            isActive={documentSlug === item.slug} 
                            documentItem={item} 
                            key={i} />
                    ))}
                </ul>
            </div>
        </div>
    );
}
 
export default DocumentList;