import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DocumentListSceleton = () => {
    return (
        <div className="card border-0 h-100 d-flex flex-column">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h6 className="card-title">Semua Document</h6>
                <div style={{ marginTop: '4px', marginBottom: '3px' }}>
                    <div className="sceleton"><FontAwesomeIcon icon="plus" fixedWidth /></div>
                </div>
            </div>
            <div className="flex-full">
                <ul className="list-group list-group-flush list-hover">
                    {[1, 2].map((i) =>
                        <li key={i} className="list-group-item border-bottom">
                            <h6 className="mb-1 text-body"><div className="sceleton">lorem</div></h6>
                            <div className="d-flex">
                                <small className="text-muted"><div className="sceleton">Last Modified Document</div></small>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}
 
export default DocumentListSceleton;