import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DiscussionListSceleton = () => {
    return (
        <div className="card border-0 h-100 d-flex flex-column">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h6 className="card-title">Semua Diskusi</h6>
                <div style={{marginTop: '4px', marginBottom: '3px'}}>
                    <div className="sceleton"><FontAwesomeIcon icon="plus" fixedWidth /></div>
                </div>
            </div>
            <div className="card-header d-flex justify-content-between align-items-center bg-light py-2">
                <ul className="list-inline mb-0">
                    {[1, 2].map((i) => 
                        <li key={i} className="list-inline-item mr-3">
                            <div className="clickable d-flex">
                                <div className="badge sceleton mr-2">3</div>
                                <small><div className="sceleton">Filter Discussion</div></small>
                            </div>
                        </li>
                    )}
                </ul>
                <div className="sceleton"><FontAwesomeIcon icon="toggle-off" fixedWidth /></div>
            </div>
            <div className="flex-full">
                <ul className="list-group list-group-flush list-hover list-discussion">
                    {[1,2].map((i) => 
                        <li key={i} className="list-group-item border-bottom">
                            <div className="media">
                                <div className="avatar mr-3 sceleton"></div>
                                <div className="media-body">
                                    <h6><div className="sceleton">lorem</div></h6>
                                    <div className="d-flex justify-content-between mb-1 text-muted">
                                        <small>
                                            <div className="d-flex justify-content-start">
                                                <div className="sceleton mr-2">Type Discussion</div>
                                                <div className="sceleton mr-2">Author Discussion</div>
                                                <div className="sceleton mr-2">Created Discussion</div>
                                            </div>
                                        </small>
                                    </div>
                                    <div className="d-flex justify-content-between mb-2 text-muted">
                                        <small>
                                            <div className="sceleton">Status Discussion</div>
                                        </small>
                                        <small>
                                            <div className="sceleton">Modified Discussion</div>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}
 
export default DiscussionListSceleton;