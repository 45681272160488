import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DiscussionilterContainer from '../../container/DiscussionFilterContainer';
import DiscussionItem from './DiscussionItem';

const DiscussionList = ({ discussion, issueTypes, setIsCreate, discussionRef }) => {
    return (
        <div className="card border-0 h-100 d-flex flex-column">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h6 className="card-title">Semua Diskusi</h6>
                <div>
                    <a
                        href="/"
                        onClick={(e) => {
                            e.preventDefault()
                            setIsCreate(true)
                        }}
                        className="text-primary">
                        <FontAwesomeIcon icon="plus" fixedWidth />
                    </a>
                    {/* <a
                        href="/"
                        className="text-primary ml-3">
                        <FontAwesomeIcon icon="search" fixedWidth />
                    </a> */}
                </div>
            </div>
            <DiscussionilterContainer />
            <div className="flex-full">
                <ul className="list-group list-group-flush list-hover list-discussion">
                    {discussion.map((discussionItem, i) => (
                        <DiscussionItem 
                            discussionItem={discussionItem}
                            issueType={issueTypes[discussionItem.type]}
                            isActive={Number(discussionRef) === discussionItem.ref}
                            key={i}/>
                    ))}
                </ul>
            </div>
        </div>
    );
}
 
export default DiscussionList;
// issueTypes