import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";

import MainContextProvider from '../contexts/MainContext';
import AppContextProvider from '../contexts/AppContext';

import LoginContainer from '../container/LoginContainer';
import MainContainer from '../container/MainContainer';

const App = () => {
    return (
        <AppContextProvider>
            <BrowserRouter>
                <Switch>
                    <Route path="/login" exact component={LoginContainer} />
                    <Route path="/" >
                        <MainContextProvider>
                            <MainContainer />
                        </MainContextProvider>
                    </Route>
                </Switch>
            </BrowserRouter>
        </AppContextProvider>
    );
}

export default App;