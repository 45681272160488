import axios from 'axios';

let sourceLoadNotification = null;

export const loadNotification = (authHandler, params, callback) => {
    if(sourceLoadNotification !== null) {
        sourceLoadNotification.cancel()
    }
    sourceLoadNotification = axios.CancelToken.source()
    axios
        .get('web-notifications', {
            params: params,
            headers: authHandler.getHeaders,
            cancelToken: sourceLoadNotification.token
        })
        .then(response => {
            sourceLoadNotification = null
            callback(true, { type: 'LOAD_NOTIFICATION', data: response.data.objects })
        })
        .catch(error => {
            console.log(error)
            if (error.response?.status === 401) {
                authHandler.invalidHandler()
            }
            callback(false, { error })
        });
}

export const readNotification = (authHandler, params, callback) => {
    axios
        .patch('web-notifications/' + params.id + '/set-as-read', {
            headers: authHandler.getHeaders
        })
        .then(response => {
            callback(true, response.data, { type: 'REMOVE_NOTIFICATION', id: params.id })
        })
        .catch(error => {
            console.log(error)
            if (error?.response?.status === 401) {
                authHandler.invalidHandler()
            }
            callback(false, { error })
        });
}