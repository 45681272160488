import React, { createContext, useReducer, useState, useCallback, useContext } from 'react'

import discussionReducer from '../reducers/discussionReducer';
import { loadDiscussion } from '../actions/discussion';
import { AppContext } from './AppContext';
import { ChannelContext } from './ChannelContext';

export const DiscussionContext = createContext();

const DiscussionContextProvider = ({ children }) => {

    const { authHandler } = useContext(AppContext);
    const { channel } = useContext(ChannelContext);

    const [discussion, discussionDispatch] = useReducer(discussionReducer, [])
    const [isCreate, setIsCreate] = useState(false)
    const [discussionRef, setDiscussionRef] = useState(false)
    const [discussionFilter, setDiscussionFilter] = useState({type: [], showClosed: false})

    const reloadDiscussion = useCallback((callback) => {
        loadDiscussion(authHandler, { 
            project: channel.id, 
            order_by: '-modified_date',
        }, (success, data) => {
            if (success) {
                discussionDispatch(data)
            }
            callback(success, data)
        })
    }, [authHandler, channel.id])
    
    return (
        <DiscussionContext.Provider value={{ 
            discussion, discussionDispatch, 
            discussionRef, reloadDiscussion, setDiscussionRef, 
            discussionFilter, setDiscussionFilter, 
            isCreate, setIsCreate, 
            }}>
            {children}
        </DiscussionContext.Provider>
    );
}

export default DiscussionContextProvider;