import React from 'react';

import DocumentListContainer from '../../container/DocumentListContainer';
import DocumentCreateContainer from '../../container/DocumentCreateContainer';
import DocumentDetailContainer from '../../container/DocumentDetailContainer';
import ActifityContextProvider from '../../contexts/ActifityContext';
import AttechmentContextProvider from '../../contexts/AttechmentContext';

const Document = () => {
    return (
        <div className="row no-gutters h-100">
            <div className="col-3 border-right h-100">
                <DocumentListContainer />
            </div>
            <div className="col d-flex flex-column h-100">
                <DocumentCreateContainer />
                <ActifityContextProvider>
                    <AttechmentContextProvider>
                        <DocumentDetailContainer />
                    </AttechmentContextProvider>
                </ActifityContextProvider>
            </div>
        </div>
    )
}
 
export default Document;