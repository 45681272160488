import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { AppContext } from '../contexts/AppContext';
import { ChannelContext } from '../contexts/ChannelContext';
import { getChannel } from '../actions/channel';

import Blank from '../components/Layout/Blank';
import Channel from '../components/Channel';
import { MainContext } from '../contexts/MainContext';
import ChannelSceleton from '../components/Sceleton/ChannelSceleton';

const ChannelContainer = () => {
    let match = useRouteMatch();
    const { authHandler } = useContext(AppContext);
    const { channelDispatch } = useContext(MainContext);
    const { setChannel } = useContext(ChannelContext);

    const [loaded, setLoaded] = useState(false)
    const [is404, setIs404] = useState(false)

    const loadCallback = useCallback((success, data, dispatchData) => {
        if (success) {
            setIs404(false)
            setChannel(data)
            channelDispatch(dispatchData)
        } else {
            setIs404(true)
        }
        setLoaded(true)
    }, [setChannel, channelDispatch])

    useEffect(() => {
        setLoaded(false)
        getChannel(authHandler, { slug: match.params.channelSlug }, loadCallback)
    }, [authHandler, match.params.channelSlug, loadCallback])

    if (!loaded) {
        return <ChannelSceleton />
    }

    if (is404) {
        return <Blank label="404 - Channel Not Found" />
    }

    return <Channel />
}

export default ChannelContainer;