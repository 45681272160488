import React, { useContext, useState, useCallback, useEffect } from 'react';

import DocumentList from '../components/Document/Documentlist';
import { DocumentContext } from '../contexts/DocumentContext';
import DocumentListSceleton from '../components/Sceleton/DocumentListSceleton';

const DocumentListContainer = () => {
    const { reloadDocument, document, documentSlug, setIsCreate } = useContext(DocumentContext)
    const [loaded, setLoaded] = useState(false)

    const reloadCallback = useCallback(() => {
        setLoaded(true)
    }, [])

    useEffect(() => {
        reloadDocument(reloadCallback)
    }, [reloadCallback, reloadDocument])

    if(!loaded) {
        return <DocumentListSceleton />
    }
    
    return <DocumentList 
        documentSlug={documentSlug}
        setIsCreate={setIsCreate}
        document={document} />
}
 
export default DocumentListContainer;