import React, { createContext, useCallback, useContext, useReducer, useState } from 'react'
import { toast } from 'react-toastify';

import { AppContext } from '../contexts/AppContext';
import { ChannelContext } from '../contexts/ChannelContext';
import attechmentReducer from '../reducers/attechmentReducer';
import { loadAttectment, inputAttectment, resetAttectment } from '../actions/attechment';
import { ActifityContext } from '../contexts/ActifityContext';

export const AttechmentContext = createContext();

const AttechmentContextProvider = ({ children }) => {

    const { authHandler } = useContext(AppContext);
    const { channel, actifityType } = useContext(ChannelContext);
    const { reloadActifity } = useContext(ActifityContext);
    const [loadingUpload, setLoadingUpload] = useState(0)
    const [displayFormCommnet, setDisplayFormCommnet] = useState(false)

    const [attecment, attechmnetDispatch] = useReducer(attechmentReducer, [])

    const reloadAttechment = useCallback((callback) => {
        loadAttectment(authHandler, actifityType, {
            object_id: actifityType.id,
            project: channel.id
        }, (success, data) => {
            if (success) {
                attechmnetDispatch(data)
            }
            callback(success, data)
        })
    }, [authHandler, actifityType, channel.id])

    const cleanAttachment = useCallback(() => {
        attechmnetDispatch(resetAttectment())
    }, [])

    const uploadCallback = useCallback((success, data) => {
        if (success) {
            attechmnetDispatch(data)
            reloadActifity(() => { })
            toast.success('Success add attechment.')
        } else {
            toast.error(data.error.response.data._error_message)
        }

        setLoadingUpload(loadingUpload - 1)
    }, [setLoadingUpload, loadingUpload, reloadActifity])

    const changeUploadHandler = e => {
        let files = e.target.files
        if (files.length > 0) {
            setLoadingUpload(files.length)
            for (let i = 0; i < files.length; i++) {
                let formData = new FormData();
                formData.append("project", channel.id);
                formData.append("object_id", actifityType.id);
                formData.append("attached_file", files[i]);
                formData.append("from_comment", false);
                inputAttectment(actifityType, authHandler, formData, uploadCallback)
            }
        }
    }

    const loadSugestionImages = useCallback((text) => {
        return new Promise((accept) => {
            setTimeout(() => {
                const suggestions = attecment.filter(item => item.thumbnail_card_url !== null).map(item => {
                    return {
                        preview: (item.description.length === 0 ? '' : item.description + ' - ') + item.name,
                        value: '![' + (item.description.length === 0 ? item.name : item.description) + '](' + item.url + ')'
                    }
                }).filter(i => i.preview.toLowerCase().includes(text.toLowerCase()));
                accept(suggestions);
            }, 250);
        });
    }, [attecment])

    const uploadPaste = (file, callback, from_comment = false) => {
        let formData = new FormData();
        formData.append("project", channel.id);
        formData.append("object_id", actifityType.id);
        formData.append("attached_file", file);
        formData.append("from_comment", from_comment);
        inputAttectment(actifityType, authHandler, formData, (success, data) => {
            if (success) {
                attechmnetDispatch(data)
                reloadActifity(() => { })
            }
            callback(success, data)
        })
    }

    const toggleDisplayFormCommnet = e => {
        e.preventDefault();
        setDisplayFormCommnet(!displayFormCommnet)
    }


    return (
        <AttechmentContext.Provider value={{ 
            attecment, 
            reloadAttechment, 
            cleanAttachment, 
            changeUploadHandler, 
            loadingUpload, 
            loadSugestionImages, 
            uploadPaste,
            displayFormCommnet,
            toggleDisplayFormCommnet, 
            }}>
            {children}
        </AttechmentContext.Provider>
    );
}

export default AttechmentContextProvider;