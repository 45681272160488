const channelsReducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_CHANNEL':
            return action.data
        case 'UPDATE_CHANNEL':
            return state.map(item => item.id === action.data.id ? action.data : item)
        default:
            return state;
    }
}

export default channelsReducer;