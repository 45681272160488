import React, { createContext, Component, useContext } from 'react';

import { eventsUrl } from "../configs/api";
import { getToken } from '../utils/helper';

const WebsocketContext = createContext()

class WebsocketContextProvider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            auth: false,
            notification: false,
            subscibe: []
        }
        this.ws = new WebSocket(eventsUrl)

        this.sendHandler = this.sendHandler.bind(this);
        this.messageHandler = this.messageHandler.bind(this);
        this.hasAuth = this.hasAuth.bind(this);
        this.authHandler = this.authHandler.bind(this);
        this.subscibeHandler = this.subscibeHandler.bind(this);
        this.unsubscibeHandler = this.unsubscibeHandler.bind(this);
        this.notificationHandler = this.notificationHandler.bind(this);
    }

    componentDidMount() {
        this.ws.onopen = () => {
            this.setState({ open: true })
            // console.log('open ws')
        }
        this.ws.onmessage = this.messageHandler
        this.ws.onerror = (e) => {
            console.log(e)
        }
        this.ws.onclose = () => {
            // console.log('close ws')
        }
    }
    componentWillUnmount() {
        this.ws.close()
    }

    sendHandler(message) {
        this.ws.send(JSON.stringify(message))
    }
    messageHandler(e){
        let message = JSON.parse(e.data)
        if (message.routing_key !== undefined) {
            if (this.state.subscibe[message.routing_key] !== undefined) {
                this.state.subscibe[message.routing_key](message)
            }
            if (typeof this.state.notification === 'function') {
                this.state.notification(message)
            }
        }
        // console.log('ws message', message)
    }

    hasAuth() {
        return this.state.auth
    }
    authHandler(token) {
        this.setState({ auth: true })
        this.sendHandler({
            cmd: 'auth', 
            data: {
                token: token, 
                sessionId: getToken(),
            }
        })
    }

    subscibeHandler(route, callback) {
        let tempSubscibe = this.state.subscibe
        tempSubscibe[route] = callback
        this.setState({ subscibe: tempSubscibe })
        this.sendHandler({
            cmd: 'subscribe',
            routing_key: route,
        })
    }
    unsubscibeHandler(route) {
        if (this.state.subscibe[route] !== undefined) {
            this.setState({ subscibe: this.state.subscibe.filter((item, i) => i !== route) })
            this.sendHandler({
                cmd: 'unsubscribe',
                routing_key: route,
            })
        }
    }
    notificationHandler(route, callback){
        this.setState({ notification: callback })
        route.forEach(route_key => {
            this.sendHandler({
                cmd: 'subscribe',
                routing_key: route_key,
            })
        });
    }

    render() {
        return (
            <WebsocketContext.Provider value={{
                hasOpen: this.state.open,
                hasAuth: this.hasAuth,
                authHandler: this.authHandler,
                subscibeHandler: this.subscibeHandler,
                unsubscibeHandler: this.unsubscibeHandler,
                notificationHandler: this.notificationHandler,
            }}>
                {this.props.children}
            </WebsocketContext.Provider>
        );
    }
}

export default WebsocketContextProvider;

export const useWebsocket = () => useContext(WebsocketContext)