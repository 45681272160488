import React from 'react';

const DiscussionDetailSceleton = () => {
    return (
        <div className="card border-0 flex-full">
            <div className="card-header py-3">
                <div className="media">
                    <div className="avatar mr-3 sceleton"></div>
                    <div className="media-body">
                        <h5 className="pb-1"><div className="sceleton">lorem</div></h5>
                        <div className="pb-1">
                            <div className="d-flex justify-content-between mb-1 text-muted">
                                <small>
                                    <div className="d-flex justify-content-start">
                                        <div className="sceleton mr-2">Type Discussion</div>
                                        <div className="sceleton mr-2">Author Discussion</div>
                                        <div className="sceleton mr-2">Created Discussion</div>
                                    </div>
                                </small>
                            </div>
                            <div className="d-flex justify-content-between mb-2 text-muted">
                                <small>
                                    <div className="d-flex justify-content-start">
                                        <div className="sceleton mr-2">Status Discussion</div>
                                        <div className="sceleton mr-2">0 Subsciber</div>
                                        <div className="sceleton mr-2">Assigned Discussion</div>
                                    </div>
                                </small>
                                <small>
                                    <div className="sceleton">Modified Discussion</div>
                                </small>
                            </div>
                        </div>
                        <small>
                            <div className="sceleton mb-1">Lorem</div>
                            <div className="sceleton mb-1">Lorem</div>
                            <div className="d-flex">
                                <div className="sceleton mb-3">Lorem ipsum dolor sit</div>
                            </div>
                        </small>
                        <div className="d-flex justify-content-between text-muted mb-2">
                            <small>
                                <div className="d-flex justify-content-start">
                                    <div className="sceleton mr-3">Add Label</div>
                                    <div className="sceleton mr-3">Add Attachment</div>
                                    <div className="sceleton mr-3">Button</div>
                                </div>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default DiscussionDetailSceleton;