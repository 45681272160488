import React, { useContext, useState, useEffect } from 'react';

import { ChannelContext } from '../contexts/ChannelContext';

import DiscussionFilter from '../components/Discussion/DiscussionFilter';
import { DiscussionContext } from '../contexts/DiscussionContext';

const DiscussionilterContainer = () => {
    const { channel } = useContext(ChannelContext);
    const { discussion, discussionFilter, setDiscussionFilter, } = useContext(DiscussionContext);
    const [typeData, setTypeData] = useState({0: 0})
    const [availableActive, setAvailableActive] = useState([])

    const filterType = e => {
        e.preventDefault()
        let id = Number(e.currentTarget.dataset.value)
        if (id === 0) {
            setDiscussionFilter({...discussionFilter, type: []})
        } else {
            if (discussionFilter.type.includes(id)) {
                setDiscussionFilter({ ...discussionFilter, type: discussionFilter.type.filter(i => i !== id)})
            } else {
                setDiscussionFilter({ ...discussionFilter, type: [...discussionFilter.type, id]})
            }
        }
    }

    const filterShowClosed = e => {
        e.preventDefault()
        setDiscussionFilter({ 
            showClosed: !discussionFilter.showClosed, 
            type: discussionFilter.showClosed ? discussionFilter.type.filter(i => availableActive.includes(i)) : discussionFilter.type
        })
    }

    const handler = {
        filterType,
        filterShowClosed, 
    }

    useEffect(() => { 
        let tempCountData = {0: 0}
        let tempAvailableActive = []
        discussion.forEach(item => {
            if (discussionFilter.showClosed || (!discussionFilter.showClosed && !item.is_closed)) {
                tempCountData[0] += 1
                tempCountData[item.type] = tempCountData[item.type] !== undefined ? tempCountData[item.type] + 1 : 1
            }
            if (!item.is_closed && !tempAvailableActive.includes(item.type)) {
                tempAvailableActive.push(item.type)
            }
        });
        setAvailableActive(tempAvailableActive)
        setTypeData(tempCountData)
    }, [discussion, discussionFilter.showClosed])

    return <DiscussionFilter 
        filter={discussionFilter}
        handler={handler}
        typeData={typeData}
        issue_types={channel.issue_types}/>
}
 
export default DiscussionilterContainer;