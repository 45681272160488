import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import DiscussionContextProvider from '../../contexts/DiscussionContext';
import DocumentContextProvider from '../../contexts/DocumentContext';

import DiscussionContainer from '../../container/DiscussionContainer';
import DocumentContainer from '../../container/DocumentContainer';
import Blank from '../Layout/Blank';
import LeftNavChannel from '../Layout/Navbar/LeftNavChannel';
import Navbar from '../Layout/Navbar';
import RightNavChannel from '../Layout/Navbar/RightNavChannel';

const Channel = () => {
    let leftNav = <LeftNavChannel/>
    let rightNav = <RightNavChannel/>
    return (
        <>
            <Navbar leftNav={leftNav} rightNav={rightNav}/>
            <div className="flex-full">
                <Switch>
                    <Redirect exact from="/channels/:channelSlug" to="/channels/:channelSlug/discussions" />
                    <Route path="/channels/:channelSlug/discussions/:discussionRef?" exact>
                        <DiscussionContextProvider>
                            <DiscussionContainer />
                        </DiscussionContextProvider>
                    </Route>
                    <Route path="/channels/:channelSlug/documents/:documentSlug?" exact>
                        <DocumentContextProvider>
                            <DocumentContainer />
                        </DocumentContextProvider>
                    </Route>
                    <Route>
                        <Blank label="404 - Page not Found" />
                    </Route>
                </Switch>
            </div>
        </> 
    );
}

export default Channel;