import React, { createContext, useReducer } from 'react'
import { toast } from 'react-toastify';

import authReducer from '../reducers/authReducer'
import { logout } from '../actions/auth'

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {

    const [auth, authDispatch] = useReducer(authReducer, {}, () => {
        const auth = localStorage.getItem('userInfo');
        return auth ? JSON.parse(auth) : {};
    })

    const logoutCallback = (success, data) => {
        if (success) {
            toast.success('Success logout.')
            authDispatch(data)
        }
    }

    const authHandler = {
        logout: e => {
            e.preventDefault();
            logout(logoutCallback)
        },
        getHeaders: auth.hasOwnProperty("auth_token") ? {
            Authorization: "Bearer " + auth.auth_token
        } : {}, 
        invalidHandler: () => {
            logout((success, data) => {
                if (success) {
                    authDispatch(data)
                }
            })
        }
    }

    return (
        <AppContext.Provider value={{ auth, authDispatch, authHandler }}>
            {children}
        </AppContext.Provider>
    );
}

export default AppContextProvider;