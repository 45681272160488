import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Attachment = ({ attecment }) => {
    return (
        <div className={`row row-gutter-1${attecment.length > 0 ? ' mt-3' : ''}`}>
            {attecment.map((item, i) =>
                <div key={i} className="col-md-4 col-lg-3 mb-1">
                    <a
                        title={item.description !== '' ? item.description : item.name}
                        href={item.url} 
                        target="_blank"
                        rel="noopener noreferrer">
                        {item.thumbnail_card_url !== null ? (
                            <img
                                src={item.thumbnail_card_url}
                                alt={item.description !== '' ? item.description : item.name}
                                title={item.description !== '' ? item.description : item.name}
                                className="thumb rounded-lg" />
                        ) : (
                                <div className="rounded-lg bg-lighten h-100 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon icon="paperclip" size="2x" className="text-muted"/>
                                </div>
                            )}
                    </a>
                </div>
            )}
        </div>
    );
}
 
export default Attachment;