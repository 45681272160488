import React from 'react';

const ActifitySceleton = () => {
    return (
        <>
            <div className="media mb-3">
                <div className="avatar mr-3 sceleton"></div>
                <div className="media-body">
                    <div className="card rounded-xl">
                        <div className="card-header py-2">
                            <div className="d-flex align-items-center">
                                <b className="mr-2"><div className="sceleton">lorem ipsum</div></b>
                                <small><div className="sceleton mr-2">Created Comment</div></small>
                            </div>
                        </div>
                        <div className="card-body pt-2">
                            <small>
                                <div className="sceleton mb-1">Lorem</div>
                                <div className="d-flex">
                                    <div className="sceleton">Lorem ipsum dolor sit</div>
                                </div>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3 ml-5">
                <div className="media ml-2 d-flex align-items-center">
                    <div className="avatar sceleton mr-2">
                    </div>
                    <div className="media-body">
                        <div className="d-flex align-items-center">
                            <b className="mr-2"><div className="sceleton">lorem ipsum</div></b>
                            <small><div className="sceleton mr-2">Actifity Data</div></small>
                            <small><div className="sceleton mr-2">Created Actifity</div></small>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default ActifitySceleton;