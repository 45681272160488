import React, { useState } from 'react';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import marked from 'marked';

const DefaultLabel = ({ actifityType, createdAt }) => {
    return <>
        <small className="text-muted mr-1">Set {actifityType}</small>
        <small className="text-muted"><Moment fromNow>{createdAt}</Moment></small>
    </>
}

const SubjectLabel = ({ actifityType, subject, createdAt }) => {
    return <>
        <small className="text-muted mr-1">Set {actifityType}</small>
        <small className="text-muted mr-1"><mark>{subject}</mark></small>
        <small className="text-muted"><Moment fromNow>{createdAt}</Moment></small>
    </>
}

const StatusLabel = ({ actifityType, name, color, createdAt }) => {
    return <>
        <small className="text-muted mr-1">Set {actifityType}</small>
        <small className="text-muted mr-1">
            <span style={{ color: color }}><FontAwesomeIcon icon="circle" className="mr-1" />{name}</span>
        </small>
        <small className="text-muted"><Moment fromNow>{createdAt}</Moment></small>
    </>
}

const TagsLabel = ({ actifityType, tags, createdAt }) => {
    let isAdd = tags[1].length > tags[0].length
    let removed = tags[0].filter(x => !tags[1].includes(x))
    return <>
        <small className="text-muted mr-1">{isAdd ? 'Add' : 'Remove'} {actifityType}</small>
        <small className="text-muted mr-1">
            <span className="tag px-1">{isAdd ? tags[1].slice(-1)[0] : removed[0]}</span>
        </small>
        <small className="text-muted"><Moment fromNow>{createdAt}</Moment></small>
    </>
}

const DescriptionLabel = ({ actifityType, description, createdAt }) => {
    const [show, setShow] = useState(false)
    return <>
        {description === '' ? (
            <small className="text-muted mr-1">Remove description</small>
        ) : (
            <>
                <small className="text-muted mr-1">Set description</small>
                <small className="mr-1">
                    <a onClick={e => {
                        e.preventDefault()
                        setShow(!show)
                    }} href="/" className="text-muted">[....]</a>
                </small>
            </>
        )}
        <small className="text-muted"><Moment fromNow>{createdAt}</Moment></small>
        {show && <div className="pt-2 markdown-preview" dangerouslySetInnerHTML={{ __html: marked(description) }} />}
    </>
}

const AssignedLabel = ({ actifityType, assigned_from, assigned_from_name , assigned_to, assigned_to_name, createdAt }) => {
    let assigned_text = <>Assigned to <b>{assigned_to_name}</b></>
    
    if(assigned_to === null) {
        assigned_text = <>Remove from <b>{assigned_from_name}</b></>
    } else if (assigned_from && assigned_to) {
        assigned_text = <>Change Assignee <b>{assigned_from_name}</b>{} &raquo; <b>{assigned_to_name}</b></>
    } 

    return <>
        <small className="text-muted mr-1">
            {assigned_text}
        </small>
        <small className="text-muted"><Moment fromNow>{createdAt}</Moment></small>
    </>
}


const ActifityItem = ({ actifityData, issueStatus, issueTypes, hiddenAttachment }) => {
    
    let actifityType = Object.keys(actifityData.diff).pop()

    const actifityToLabel = () => {
        switch (actifityType) {
            case 'subject':
                return <SubjectLabel
                    actifityType={actifityType}
                    subject={actifityData.diff.subject[1]}
                    createdAt={actifityData.created_at} />
            case 'status':
                let status_id = actifityData.diff.status[1]

                return <StatusLabel
                    actifityType={actifityType}
                    name={issueStatus[status_id] ? issueStatus[status_id].name : 'UNKNOWN ('+status_id+')'}
                    color={issueStatus[status_id] ? issueStatus[status_id].color: '#ccc'}
                    createdAt={actifityData.created_at} />
            case 'tags':
                return <TagsLabel
                    actifityType={actifityType}
                    tags={actifityData.diff.tags}
                    createdAt={actifityData.created_at} />
            case 'description_html':
                return <DescriptionLabel
                    actifityType={actifityType}
                    description={actifityData.diff.description[1]}
                    createdAt={actifityData.created_at} />
            case 'assigned_to':
                return <AssignedLabel
                    actifityType={actifityType}
                    assigned_from={actifityData.diff.assigned_to[0]}
                    assigned_from_name={actifityData.values.users[actifityData.diff.assigned_to[0]]}
                    assigned_to={actifityData.diff.assigned_to[1]}
                    assigned_to_name={actifityData.values.users[actifityData.diff.assigned_to[1]]}
                    createdAt={actifityData.created_at} />
            case 'type':
                let type_id = actifityData.diff.type[1]

                return <StatusLabel
                    actifityType={actifityType}
                    name={issueTypes[type_id] ? issueTypes[type_id].name : 'UNKNOWN (' + type_id + ')'}
                    color={issueTypes[type_id] ? issueTypes[type_id].color : '#ccc'}
                    createdAt={actifityData.created_at} />
            default: 
                return <DefaultLabel
                    actifityType={actifityType}
                    createdAt={actifityData.created_at} />
        }
    }

    const getActivityIconName = () => {
        switch (actifityType) {
            case 'subject': return "pencil-alt"
            case 'description_html': return "align-center"
            case 'assigned_to': return "user"
            case 'tags': return "tag"
            default: return "bookmark"
        }
    }
    
    if (actifityData.values_diff.attachments?.new[0]?.id && hiddenAttachment.includes(actifityData.values_diff.attachments.new[0].id)){
        return <></>
    }

    return (
        <div className="mb-3 ml-5">
            <div className="media ml-2 d-flex align-items-center">
                <div className="avatar bg-light mr-2">
                    <FontAwesomeIcon icon={getActivityIconName()} className="text-muted" size='lg' />
                </div>
                <div className="media-body">
                    <b className="mr-2">{actifityData.user.name}</b>
                    {actifityToLabel()}
                </div>
            </div>
        </div>
    );
}

export default ActifityItem;