import React from 'react';
import Moment from 'react-moment';
import marked from 'marked';

import Media from '../Layout/Media';

const CommentItem = ({ commentData }) => {
    const dateFormat = React.useCallback((datetime) => {
        if(new Date().toDateString() === new Date(datetime).toDateString()){
            return "HH:mm"
        } else {
            return "dddd, DD MMM YYYY HH:mm"
        }
    }, [])

    return (
        <Media userData={{ photo: commentData.user.photo, name: commentData.user.name}}>
            <div className="card rounded-xl">
                <div className="card-header py-2 d-flex align-items-center">
                    <b className="mr-2">{commentData.user.name}</b>
                    <small className="text-muted"><Moment fromNow>{commentData.created_at}</Moment></small>
                    <div className="flex-full"></div>
                    <small className="text-muted"><Moment format={dateFormat(commentData.created_at)}>{commentData.created_at}</Moment></small>
                </div>
                <div className="card-body pt-2">
                    <div dangerouslySetInnerHTML={{ __html: marked(commentData.comment) }} className="markdown-preview" />
                </div>
            </div>
        </Media>
    );
}
 
export default CommentItem;