import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

const DocumentItem = ({ documentItem, isActive }) => {
    return (
        <Link
            to={'/channels/' + documentItem.project_extra_info.slug + '/documents/' + documentItem.slug}
            title={documentItem.title}
            className="text-decoration-none" >
            <li className={`list-group-item border-bottom${isActive ? ' bg-active' : ''}`}>
                <h6 className="mb-1 text-body">{documentItem.title}</h6>
                <small className="text-muted">Diperbarui: <Moment format="D MMM YYYY">{documentItem.modified_date}</Moment></small>
            </li>
        </Link>
    );
}
 
export default DocumentItem;