import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ChannelContextProvider from '../contexts/ChannelContext';

import ChannelContainer from "../container/ChannelContainer";
import MainLayout from './Layout/MainLayout';
import Blank from './Layout/Blank';
import Dashboard from './Dashboard';

const Main = () => {
    return (
        <MainLayout>
            <ChannelContextProvider>
                <Switch>
                    <Redirect exact from="/" to="/dahsboard" />
                    <Route path="/dahsboard" exact >
                        <Dashboard />
                    </Route>
                    <Route path="/channels" exact component={Blank} />
                    <Route path="/channels/:channelSlug">
                        <ChannelContainer />
                    </Route>
                    <Route component={Blank} />
                </Switch>
            </ChannelContextProvider>
        </MainLayout>
    );
}

export default Main;