import React from 'react';
import UserAvatar from "react-user-avatar"

const Media = ({ userData, children }) => {
    return (
        <div className="media">
            <div className="avatar mr-3">
                <UserAvatar size="40" name={userData.name} className="text-white" src={userData.photo} />
            </div>
            <div className="media-body">{ children }</div>
        </div>
    );
}
 
export default Media;