import React, { useEffect, useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { AppContext } from '../contexts/AppContext';
import { useWebsocket } from '../contexts/WebsocketContext';
import Main from '../components/Main';
import { MainContext } from '../contexts/MainContext';

const MainContainer = () => {

    const history = useHistory()
    const { hasOpen, hasAuth, authHandler, notificationHandler } = useWebsocket()
    
    const { auth } = useContext(AppContext);
    const { checkNotification } = useContext(MainContext)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (Object.keys(auth).length === 0) {
            history.push('/login')
        }
    }, [auth, history]);

    useEffect(() => {
        if (hasOpen && Object.keys(auth).length !== 0 && !hasAuth()) {
            authHandler(auth.auth_token)
        }
    }, [auth, hasAuth, authHandler, hasOpen]);

    useEffect(() => {
        let interval = null

        if (Object.keys(auth).length !== 0) {
            checkNotification(() => {
                setLoaded(true)
            })

            // let route = ['notifications', 'web_notifications.' + auth.id]

            // if (loaded && hasOpen) {
            //     notificationHandler(route, () => {
            //         checkNotification()
            //     })
            // }
            if (loaded) {
                interval = setInterval(checkNotification, 3000);
            }
        }
        return () => {
            if(interval !== null) {
                clearInterval(interval); 
            }
        }
    }, [auth, loaded, hasOpen, notificationHandler, checkNotification])

    if (Object.keys(auth).length === 0) {
        return <Redirect to='/login' />
    }

    return <Main />
}

export default MainContainer;