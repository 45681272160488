import React from 'react';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import Tag from '../Layout/Tag';
import Media from '../Layout/Media';

const DiscussionItem = ({ discussionItem, issueType, isActive }) => {
    return (
        <li className={`list-group-item border-bottom${isActive ? ' bg-active' : ''}`}>
            <Link
                to={'/channels/' + discussionItem.project_extra_info.slug + '/discussions/' + discussionItem.ref}
                title={discussionItem.subject}
                className="text-decoration-none show-hover" >
                <span style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    width: 4,
                    backgroundColor: issueType.color
                }} title={issueType.name}/>
                <Media userData={{ photo: discussionItem.owner_extra_info.photo, name: discussionItem.owner_extra_info.full_name_display }}>
                    <h6 className={(discussionItem.is_blocked || discussionItem.is_closed) ? 'text-muted' : 'text-body'}>{discussionItem.subject}</h6>
                    <div className="d-flex justify-content-between mb-1 text-muted">
                        <small>
                            <FontAwesomeIcon icon="circle" fixedWidth className="mr-1" style={{ color: discussionItem.status_extra_info.color }} /> 
                            <span className="mr-2">{discussionItem.status_extra_info.name}</span>

                            {/* {!issueType.name.startsWith("__") && issueType.name} by {discussionItem.owner_extra_info.full_name_display}  */}
                            {issueType.name} 
                            {/* by {discussionItem.owner_extra_info.full_name_display}  */}
                            &nbsp;&middot;&nbsp;
                            <Moment format="DD MMM YYYY HH:mm">{discussionItem.created_date}</Moment>
                        </small>
                    </div>
                    <div className="d-flex mb-2 text-muted text-nowrap">
                        <small style={{textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 270}}>
                            {discussionItem.latest_comment !== null && (
                                <>
                                    <strong>{discussionItem.latest_comment.user.name}</strong>:&nbsp;
                                    {discussionItem.latest_comment.comment}
                                </>
                            )}
                        </small>
                        <span className={"flex-full"}></span>
                        <small>
                            <Moment fromNow ago>{discussionItem.modified_date}</Moment>
                        </small>
                    </div>
                    {(discussionItem.tags.length > 0) && <Tag tagLists={discussionItem.tags} />}
                </Media>
            </Link>
        </li>
    );
}

export default DiscussionItem;
