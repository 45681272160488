import React, { createContext, useReducer, useCallback, useContext } from 'react'

import actifityReducer from '../reducers/actifityReducer';
import { AppContext } from './AppContext';
import { ChannelContext } from './ChannelContext';
import { loadActifity } from '../actions/actifity';
import { useState } from 'react';

export const ActifityContext = createContext();

const ActifityContextProvider = ({ children }) => {

    const { authHandler } = useContext(AppContext);
    const { actifityType } = useContext(ChannelContext);
    const [actifity, actifityDispatch] = useReducer(actifityReducer, [])

    const [lastVersion, setLastVersion] = useState(0)
    
    const reloadActifity = useCallback((callback) => {
        if (lastVersion !== actifityType.version) {
            setLastVersion(actifityType.version)
            loadActifity(authHandler, actifityType, {}, (success, data) => {
                if (success) {
                    actifityDispatch(data)
                }
                callback(success, data)
            })
        }
    }, [authHandler, actifityType, actifityDispatch, lastVersion])

    return (
        <ActifityContext.Provider value={{ actifity, reloadActifity }}>
            {children}
        </ActifityContext.Provider>
    );
}

export default ActifityContextProvider;