import React, { createContext, useReducer, useContext, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom';

import { AppContext } from './AppContext';
import WebsocketContextProvider from '../contexts/WebsocketContext';

import { loadNotification, readNotification } from '../actions/notification';
import channelsReducer from '../reducers/channelReducer';
import notificationReducer from '../reducers/notificationReducer';
import { arrayColumn } from '../utils/helper';

export const MainContext = createContext();

const MainContextProvider = ({ children }) => {
    const history = useHistory()

    const { authHandler } = useContext(AppContext);
    const [channel, channelDispatch] = useReducer(channelsReducer, [])

    const [notification, notificationDispatch] = useReducer(notificationReducer, [])
    const [minimized, setMinimizedState] = useState(() => {
        return JSON.parse(localStorage.getItem('minimized'))
    })

    const setMinimized = useCallback(() => {
        setMinimizedState(!minimized)
        localStorage.setItem('minimized', JSON.stringify(!minimized));
    }, [minimized])

    const playNotificationSound = useCallback(() => {
        let audio = new Audio('/sounds/notification.ogg');
        const audioPromise = audio.play()
        if (audioPromise !== undefined) {
            audioPromise
                .then(_ => {
                    // audio play started
                })
                .catch(err => {
                    // catch dom exception
                    console.info(err)
                })
        }
    }, [])

    const checkNotification = useCallback((callback) => {
        loadNotification(authHandler, {
            'only_unread': 'true' 
        }, (success, data) => {
            if (success) {
                notificationDispatch(data)
                
                let notification = data.data
                let lastNotifTime = localStorage.getItem('lastNotifTime') || '1990-01-01T01:01:01+0000' 

                if( notification.length > 0 ){
                    if( new Date(notification[0].created) > new Date(lastNotifTime) ) {
                        // console.log("Unekno Notif e. trus simpen tanggal terbaru")
                        playNotificationSound()
                        localStorage.setItem('lastNotifTime', notification[0].created);
                    }
                }
            }
            if (typeof callback === "function") {
                callback(success)
            }
        })
    }, [authHandler, playNotificationSound])

    const markReadNotification = useCallback(notification => {
        let tempChannel = arrayColumn(channel, 'id')
        readNotification(authHandler, { 
            id: notification.id 
        }, (success, data, dataDispatch) => {
            if (success){
                notificationDispatch(dataDispatch)
                let projectSLug = tempChannel[notification.data.project.id] ? tempChannel[notification.data.project.id].slug : notification.data.project.slug
                if (notification.data?.obj?.content_type === 'issue'){
                    history.push('/channels/' + projectSLug + '/discussions/' + notification.data.obj.ref)
                }else{
                    history.push('/channels/' + projectSLug)
                }
            }
        })
    }, [authHandler, history, channel])

    return (
        <MainContext.Provider value={{ channel, channelDispatch, notification, checkNotification, readNotification: markReadNotification, minimized, setMinimized }}>
            <WebsocketContextProvider>
                {children}
            </WebsocketContextProvider>
        </MainContext.Provider>
    );
}

export default MainContextProvider;