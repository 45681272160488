import React from 'react';

import CommentCreateContainer from '../../container/CommentCreateContainer';

import CommentItem from '../Comment/CommentItem';
import ActifityItem from './ActifityItem';
import ActifitySceleton from '../Sceleton/ActifitySceleton';

const Actifity = ({ actifity, issueStatus, issueTypes, hiddenAttachment, loaded }) => {
    return (
        <div className="timeline">
            {!loaded ? (
                <ActifitySceleton />
            ) : (
                actifity.map(actifityItem => 
                    actifityItem.comment_html !== "" ? (
                        <div className="mb-3" key={actifityItem.id}>
                            <CommentItem commentData={actifityItem} />
                        </div>
                    ) : (
                            <ActifityItem key={actifityItem.id} hiddenAttachment={hiddenAttachment} actifityData={actifityItem} issueStatus={issueStatus} issueTypes={issueTypes}/>
                    )
                )
            )}
            <CommentCreateContainer />
        </div>
    );
}

export default Actifity;