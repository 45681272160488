import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import marked from 'marked';

import { colors } from '../../configs/app';

import Media from '../Layout/Media';
import ActifityContainer from '../../container/ActifityContainer';
import AttachmentContrainer from '../../container/AttachmentContainer'
import MdEditor from '../Layout/MdEditor';

const DiscussionDetail = (props) => {
    let {
        discussionItem,
        isCreate,
        issue_statuses,
        setFocus,
        tempChange,
        changeValueData,
        loadingChange,
        changeDataHandler,
        loadSuggestions,
        handlerChangeDescription,
        addTagHandler,
        removeTagHandler,
        tags_colors,
        changeUploadHandler,
        loadingUpload,
        issue_types,
        uploadPaste,
        // displayCommnetAttechment,
        // toggleDisplayCommnetAttechment,
        members,
        addSubscriberHandler,
        removeSubscriberHandler,
        addAllSubscriberHandler,
    } = props

    let filtered_tags = Object.entries(tags_colors).filter(tag => {
        return tag[0].includes(tempChange.tag_name === undefined ? '' : tempChange.tag_name)
    })

    const [showColorOptions, setShowColorOptions] = useState(false)
    const inputAttechmentRef = useRef(null)
    
    const commentEndRef = useRef(null)

    const scrollToBottom = () => {
        commentEndRef.current.scrollIntoView({ behavior: "smooth" })
    }

    return (
        <div className={`card border-0 flex-full${isCreate ? ' d-none' : ''}`}>
            <div className="card-header py-3">
                <Media userData={{ photo: discussionItem.owner_extra_info.photo, name: discussionItem.owner_extra_info.full_name_display }}>
                    <div className="d-flex">
                        {tempChange.focus === 'subject' ? (
                            <form
                                data-change-key="subject"
                                data-change-value={tempChange.subject === undefined ? discussionItem.subject : tempChange.subject}
                                onSubmit={changeDataHandler}
                                className="form-group w-100">
                                <input
                                    name="subject"
                                    placeholder="Discussion Subject"
                                    className="form-control"
                                    value={tempChange.subject === undefined ? discussionItem.subject : tempChange.subject}
                                    onChange={changeValueData}
                                    required
                                    type="text" />
                                <div className="text-right mt-1">
                                    {loadingChange ? (
                                        <small className="text-muted mr-2">Loading...</small>
                                    ) : (
                                            <a
                                                onClick={changeDataHandler}
                                                data-change-key="subject"
                                                data-change-value={tempChange.subject === undefined ? discussionItem.subject : tempChange.subject}
                                                className="mr-2"
                                                href="/">
                                                <FontAwesomeIcon icon="save" fixedWidth />
                                            </a>
                                        )}
                                    <a
                                        data-change-key=""
                                        onClick={setFocus}
                                        className="text-muted"
                                        href="/">
                                        <FontAwesomeIcon icon="times" fixedWidth />
                                    </a>
                                </div>
                            </form>
                        ) : (
                            <h5 style={{fontWeight: 600}}
                                data-change-key="subject"
                                onDoubleClick={setFocus}
                                className={`mb-1 w-100${(discussionItem.is_blocked || discussionItem.is_closed) ? ' text-muted' : ''}`} >
                                {discussionItem.subject}
                            </h5>
                        )}

                        <span onClick={()=>{ return scrollToBottom(); }}
                            className="clickable text-muted ml-2"
                            title="Jump to last comment"
                            style={{fontSize: "1.3em"}}>
                            <FontAwesomeIcon icon={['far', 'arrow-alt-circle-down']} />
                        </span>
                        
                    </div>
                    <div className="text-muted">
                        <span className="dropdown">
                            <a
                                data-change-key="type"
                                onClick={setFocus}
                                data-toggle="dropdown"
                                className="text-decoration-none mr-1 show-hover text-muted"
                                href="/" >
                                <small>
                                    <FontAwesomeIcon icon="circle" fixedWidth className="mr-1"
                                        style={{ color: issue_types[discussionItem.type].color }} />
                                    {issue_types[discussionItem.type].name}
                                </small>
                                <span className="show-hover-target caret"></span>
                            </a>
                            {tempChange.focus === 'type' && loadingChange && <small className="text-muted mx-1">Loading...</small>}
                            <div className="dropdown-menu mt-1">
                                {issue_types.map((type, i) =>
                                    discussionItem.type !== i && (
                                        <a
                                            key={i}
                                            onClick={changeDataHandler}
                                            data-change-key="type"
                                            data-change-value={type.id}
                                            href="/" className="dropdown-item" >
                                            <small>
                                                <FontAwesomeIcon icon="circle" fixedWidth className="mr-2"
                                                    style={{ color: type.color }} />
                                                <span>{type.name}</span>
                                            </small>
                                        </a>
                                    )
                                )}
                            </div>
                        </span>
                        <small>
                            by {discussionItem.owner_extra_info.full_name_display} on <Moment format="D MMM YYYY" withTitle>{discussionItem.created_date}</Moment>
                        </small>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2 text-muted">
                        <div>
                            <span className="dropdown">
                                <a
                                    data-change-key="status"
                                    onClick={setFocus}
                                    href="/" data-toggle="dropdown"
                                    className="text-muted text-decoration-none show-hover mr-2">
                                    <small>
                                        <FontAwesomeIcon icon="circle" fixedWidth className="mr-1" style={{ color: discussionItem.status_extra_info.color }} />
                                        <span>{discussionItem.status_extra_info.name}</span>
                                    </small>
                                    <span className="show-hover-target caret"></span>
                                </a>
                                {tempChange.focus === 'status' && loadingChange && <small className="text-muted mx-1">Loading...</small>}
                                <div className="dropdown-menu mt-1">
                                    {issue_statuses.map((status, i) =>
                                        status.name !== discussionItem.status_extra_info.name && (
                                            <a
                                                key={i}
                                                onClick={changeDataHandler}
                                                data-change-key="status"
                                                data-change-value={status.id}
                                                href="/" className="dropdown-item" >
                                                <small>
                                                    <FontAwesomeIcon icon="circle" fixedWidth className="mr-2"
                                                        style={{ color: status.color }} />
                                                    <span>{status.name}</span>
                                                </small>
                                            </a>
                                        )
                                    )}
                                </div>
                            </span>
                            <span className="dropdown">
                                <a
                                    data-change-key="subsciber"
                                    onClick={setFocus}
                                    href="/" data-toggle="dropdown"
                                    className="text-muted text-decoration-none show-hover mr-2">
                                    <small>{discussionItem.watchers.length} Subscriber</small>
                                    <span className="show-hover-target caret"></span>
                                </a>
                                {tempChange.focus === 'subsciber' && loadingChange && <small className="text-muted mx-1">Loading...</small>}
                                <div className="dropdown-menu mt-1">
                                    {members.map((member, i) =>
                                        discussionItem.watchers.includes(member.id) && 
                                        <a
                                        key={i}
                                            onClick={addSubscriberHandler}
                                            data-value={member.id}
                                            href="/" className="dropdown-item show-hover" >
                                            <small>
                                                <FontAwesomeIcon icon="check" fixedWidth className="mr-2" />
                                                {member.full_name_display}
                                                <span className="float-right pt-1">
                                                    <FontAwesomeIcon icon="times" className="show-hover-target text-muted"/>
                                                </span>
                                            </small>
                                        </a>
                                    )}
                                    {members.map((member, i) =>
                                        !discussionItem.watchers.includes(member.id) && 
                                        <a
                                            key={i}
                                            onClick={removeSubscriberHandler}
                                            data-value={member.id}
                                            href="/" className="dropdown-item show-hover" >
                                            <small>
                                                <FontAwesomeIcon icon="circle" fixedWidth className="mr-2" />
                                                {member.full_name_display}
                                                <span className="float-right pt-1">
                                                    <FontAwesomeIcon icon="plus" className="show-hover-target text-muted" />
                                                </span>
                                            </small>
                                        </a>
                                    )}
                                    {members.length > discussionItem.watchers && (
                                        <>
                                            <div className="dropdown-divider"></div>
                                            <a
                                                onClick={addAllSubscriberHandler}
                                                href="/" className="dropdown-item show-hover" >
                                                <small>
                                                    <FontAwesomeIcon icon="circle" fixedWidth className="mr-2" />
                                                    All Subsciber
                                                </small>
                                            </a>
                                        </>
                                    )}
                                </div>
                            </span>
                            {discussionItem.assigned_to && <small className="mr-1" >Assigned to</small>}
                            <span className="dropdown">
                                <a
                                    data-change-key="assigned_to"
                                    onClick={setFocus}
                                    href="/" data-toggle="dropdown"
                                    className="text-muted text-decoration-none show-hover">
                                    <small>{discussionItem.assigned_to ? discussionItem.assigned_to_extra_info.full_name_display : 'Unassigned'}</small>
                                    <span className="show-hover-target caret"></span>
                                </a>
                                {tempChange.focus === 'assigned_to' && loadingChange && <small className="text-muted mx-1">Loading...</small>}
                                <div className="dropdown-menu mt-1">
                                    {members.map((member, i) =>
                                        member.is_active && member.id !== discussionItem.assigned_to && (
                                            <a
                                                key={i}
                                                onClick={changeDataHandler}
                                                data-change-key="assigned_to"
                                                data-change-value={member.id}
                                                href="/" className="dropdown-item" >
                                                <small>{member.full_name_display}</small>
                                            </a>
                                        )
                                    )}
                                    {discussionItem.assigned_to && (
                                        <>
                                            <div className="dropdown-divider"></div>
                                            <a
                                                onClick={changeDataHandler}
                                                data-change-key="assigned_to"
                                                data-change-value={null}
                                                href="/" className="dropdown-item" >
                                                <small>Remove Assigned</small>
                                            </a>
                                        </>
                                    )}
                                </div>
                            </span>
                        </div>
                        <div>
                            <small>Last update: <Moment fromNow withTitle>{discussionItem.modified_date}</Moment></small>
                        </div>
                    </div>
                    <div>
                        {tempChange.focus === 'description' ? (
                            <form
                                data-change-key="description"
                                data-change-value={tempChange.description === undefined ? discussionItem.description : tempChange.description}
                                onSubmit={changeDataHandler}
                                className="form-group">
                                <MdEditor
                                    placeholder="Discussion Description"
                                    setValue={handlerChangeDescription}
                                    loadSuggestions={loadSuggestions}
                                    uploadPaste={uploadPaste}
                                    value={tempChange.description === undefined ? discussionItem.description : tempChange.description} />
                                <div className="mt-1 text-right">
                                    {loadingChange ? (
                                        <small className="text-muted mr-2">Loading...</small>
                                    ) : (
                                            <a
                                                onClick={changeDataHandler}
                                                data-change-key="description"
                                                className="mr-2"
                                                data-change-value={tempChange.description === undefined ? discussionItem.description : tempChange.description}
                                                href="/">
                                                <FontAwesomeIcon icon="save" fixedWidth />
                                            </a>
                                        )}
                                    <a
                                        data-change-key=""
                                        onClick={setFocus}
                                        className="text-muted"
                                        href="/">
                                        <FontAwesomeIcon icon="times" fixedWidth />
                                    </a>
                                </div>
                            </form>
                        ) : (
                                <div
                                    data-change-key="description"
                                    onDoubleClick={setFocus}>
                                    {discussionItem.description_html ? (
                                        <div dangerouslySetInnerHTML={{ __html: marked(discussionItem.description) }} className="markdown-preview" />
                                    ) : (
                                            <div className="text-muted mb-3">Empty description</div>
                                        )}
                                </div>
                            )}
                    </div>

                    {/* SECTION Action Buttons */}
                    <div className="small text-muted mt-3 mb-2">
                        {tempChange.focus === 'tags' && loadingChange ? <span className="mr-3">Loading...</span> : (
                            <div className="dropdown mr-3" style={{ display: "inline-block" }}>
                                <a
                                    data-change-key="tags"
                                    onClick={setFocus}
                                    data-toggle="dropdown"
                                    href="/" className="text-muted text-decoration-none text-nowrap">
                                    <FontAwesomeIcon icon="tag" className="mr-1" fixedWidth />Add Label
                                    </a>
                                <form
                                    data-change-value-name={tempChange.tag_name}
                                    data-change-value-color={tempChange.tag_color === undefined ? '#CD1C86' : tempChange.tag_color}
                                    onSubmit={addTagHandler}
                                    className="dropdown-menu mt-2">
                                    <div className="px-2 mb-2">
                                        <input
                                            value={tempChange.tag_name === undefined ? '' : tempChange.tag_name}
                                            onChange={changeValueData}
                                            onKeyUp={changeValueData}
                                            name='tag_name'
                                            type="text"
                                            required
                                            autoComplete="off"
                                            placeholder="Search or create..."
                                            className="form-control form-control-sm mr-2" />
                                    </div>
                                    <div style={{ maxHeight: '180px', overflow: 'auto' }}>
                                        {filtered_tags.map((tag, i) =>
                                            !Object.fromEntries(discussionItem.tags).hasOwnProperty(tag[0]) ? (
                                                <a
                                                    key={i}
                                                    data-change-key="tags"
                                                    data-change-value-name={tag[0]}
                                                    data-change-value-color={tag[1]}
                                                    onClick={addTagHandler}
                                                    href="/" className="dropdown-item px-2" >
                                                    <FontAwesomeIcon icon="square" fixedWidth
                                                        className="mr-2"
                                                        style={{ color: tag[1] }} />
                                                    <small>
                                                        <span>{tag[0]}</span>
                                                    </small>
                                                </a>
                                            ) : (
                                                    <span key={i} className="dropdown-item px-2 text-muted">
                                                        <FontAwesomeIcon icon="check" fixedWidth
                                                            className="mr-2"
                                                            style={{ color: tag[1] }} />
                                                        <small>
                                                            <span>{tag[0]}</span>
                                                        </small>
                                                    </span>
                                                )
                                        )}
                                    </div>

                                    {tempChange.tag_name && !filtered_tags.length && (
                                        <>
                                            <div className="d-flex px-2">
                                                <button
                                                    type="button"
                                                    onClick={() => { setShowColorOptions(!showColorOptions) }}
                                                    className="btn btn-default btn-sm px-0 mr-2 float-left"
                                                    style={{
                                                        width: 38,
                                                        background: tempChange.tag_color === undefined ? '#CD1C86' : tempChange.tag_color
                                                    }} />
                                                <button className="btn btn-default btn-sm btn-block">Create "{tempChange.tag_name}"</button>
                                            </div>

                                            <div className="px-2 mt-2">
                                                {showColorOptions && Object.keys(colors).map((key) => (
                                                    <button type="button"
                                                        key={key}
                                                        onClick={() => {
                                                            tempChange.tag_color = colors[key]
                                                            setShowColorOptions(false)
                                                        }}
                                                        className="btn btn-default p-0 mr-1 mb-1"
                                                        style={{ width: 20, height: 20, background: colors[key] }} />
                                                ))}
                                            </div>
                                        </>
                                    )}

                                </form>
                            </div>
                        )}

                        {loadingUpload > 0 ? <span className="mr-3">Loading...</span> : (
                            <>
                                <a href="/" onClick={e => {
                                    e.preventDefault()
                                    inputAttechmentRef.current.click()
                                }} className="text-muted text-decoration-none mr-3">
                                    <FontAwesomeIcon icon="paperclip" className="mr-1" fixedWidth />Add Attachment
                                </a>
                                <div className="d-none">
                                    <input
                                        onChange={changeUploadHandler}
                                        type="file" multiple
                                        id="input-attechment"
                                        ref={inputAttechmentRef} />
                                </div>
                            </>
                        )}

                        <div className="dropdown mr-3" style={{ display: "inline-block" }}>
                            <a
                                data-toggle="dropdown"
                                className="text-muted text-decoration-none"
                                href="/"><FontAwesomeIcon icon="ellipsis-h" className="mr-1" fixedWidth />More</a>
                            <div className="dropdown-menu mt-2">
                                <a
                                    data-change-key="subject"
                                    onClick={setFocus}
                                    className="dropdown-item" href="/">Edit Subject</a>
                                <a
                                    data-change-key="description"
                                    onClick={setFocus}
                                    className="dropdown-item" href="/">Edit Description</a>
                                {/* <a
                                    onClick={toggleDisplayCommnetAttechment}
                                    className="dropdown-item" href="/">{displayCommnetAttechment ? 'Hide' : 'Show'} Comment Attachment</a> */}
                            </div>
                        </div>
                    </div>

                    {/* SECTION List Tags */}
                    {discussionItem.tags.length > 0 && (
                        <ul className="list-inline mb-0">
                            {discussionItem.tags.map((tag, i) => (
                                <li className="list-inline-item mr-1" key={i}>
                                    <small className="tag" style={{ borderColor: tag[1], color: tag[1] }}>
                                        {tag[0]}
                                        {!loadingChange ? (
                                            <a data-tag-key={i} onClick={removeTagHandler} className="text-muted mx-1" href="/">
                                                <FontAwesomeIcon icon="times" size="sm" />
                                            </a>
                                        ) : (
                                                <span className="text-muted mx-1" >
                                                    <FontAwesomeIcon icon="times" size="sm" />
                                                </span>
                                            )}
                                    </small>
                                </li>
                            ))}
                        </ul>
                    )}

                    {/* SECTION Attachment List */}
                    <AttachmentContrainer />
                </Media>
            </div>
            <div className="card-body py-3">
                <ActifityContainer />
                <div ref={commentEndRef} />
            </div>
        </div>
    );
}

export default DiscussionDetail;