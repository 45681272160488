import React, { useEffect, useState, useContext, useCallback } from 'react';
import { withRouter } from 'react-router-dom';

import { AppContext } from '../contexts/AppContext';
import { MainContext } from '../contexts/MainContext';
import { mainNavs } from '../configs/navs';
import { loadChannel } from '../actions/channel';

import Sidenav from '../components/Layout/Sidenav/Sidenav';

const SidenavContainer = ({ location }) => {
    const { auth, authHandler } = useContext(AppContext);
    const { channel, channelDispatch } = useContext(MainContext);
    
    const [loaded, setLoaded] = useState(false)
    const [navItems, setNavItems] = useState([...mainNavs.navItems, mainNavs.loadingNavItem])

    const renderNavs = useCallback(() => {
        let navItems = [...mainNavs.navItems]
        if (loaded) {
            channel.forEach((channelItem) => {
                navItems.push({
                    key: channelItem.id,
                    path: '/channels/' + channelItem.slug,
                    icon: 'hashtag',
                    label: channelItem.name
                })
            })
        }else{
            navItems.push(mainNavs.loadingNavItem)
        }
        setNavItems(navItems)
    }, [channel, loaded])

    useEffect(() => {
        renderNavs()
    }, [channel, renderNavs])

    const loadChannelCallback = useCallback((success, data) => {
        if ( success ) {
            channelDispatch(data)
        }
        setLoaded(true)
    }, [channelDispatch])
    
    useEffect(() => {
        if (!loaded) {
            loadChannel(authHandler, { member: auth.id, order_by: 'user_order' }, loadChannelCallback)
        }
    }, [loaded, authHandler, auth, loadChannelCallback])

    return <Sidenav 
        navItems={navItems} 
        routePath={location.pathname} 
        logoutHanler={authHandler.logout} 
        authData={auth}/>;
}
 
export default withRouter(SidenavContainer);