import React from 'react';
import MdEditor from '../Layout/MdEditor';

const DocumentCreate = ({ isCreate, formData, handler, loadinForm, loadSuggestions }) => {
    return (
        <div className={`row no-gutters flex-full${isCreate ? '' : ' d-none'}`}>
            <div className="col-8 mx-auto py-4">
                <form onSubmit={handler.submitHandler}>
                    <div className="form-group">
                        <input
                            value={formData.title}
                            onChange={handler.handlerChangeTitle}
                            placeholder="Title Document"
                            className="form-control"
                            required
                            type="text"/>
                    </div>
                    <div className="form-group">
                        <MdEditor
                            placeholder="Content Document"
                            setValue={handler.handlerChangeContent}
                            loadSuggestions={loadSuggestions}
                            previewMdClass="markdown-body"
                            value={formData.content} />
                    </div>
                    <div className="text-right">
                        <button
                            onClick={handler.closeHandler}
                            type="button"
                            className="btn btn-outline-secondary rounded-pill mr-1">Close</button>
                        {loadinForm ? (
                            <button className="btn btn-primary rounded-pill disabled" disabled>Loading...</button>
                        ) : (
                                <button className="btn btn-primary rounded-pill">Post Document</button>
                            )}
                    </div>
                </form>
            </div>
        </div>
    );
}
 
export default DocumentCreate;