import React from 'react';
import { Link, matchPath } from 'react-router-dom';

const NavbarItem = ({ routePath, path, label }) => {
    return (
        <li className={`nav-item${matchPath(routePath, { path: path }) ? ' active' : ''}`}>
            <Link className="nav-link" to={path} title={label}>{label}</Link>
        </li>
    );
}

export default NavbarItem;