import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactMde from 'react-mde';
import autosize from 'autosize';
import marked from 'marked';
import DataTransfer from 'fbjs/lib/DataTransfer';
import TurndownService from 'turndown';

const MdEditor = (props) => {
    const { 
        value, 
        setValue, 
        minEditorHeight, 
        loadSuggestions, 
        placeholder, 
        uploadPaste, 
        previewMdClass
    } = props

    const [selectedTab, setSelectedTab] = useState("write");
    const [loadingUpload, setLoadingUpload] = useState(false);
    const refs = useRef(null)
    
    useEffect(() => {
        autosize(refs.current.finalRefs.textarea.current)
    }, [value])

    const addTextValue = useCallback((text) => {
        let target = refs.current.finalRefs.textarea.current
        if (document.selection) {
            // IE
            setValue(text)
        } else if (target.selectionStart || target.selectionStart === 0) {
            // Others
            var startPos = target.selectionStart;
            var endPos = target.selectionEnd;
            setValue(target.value.substring(0, startPos) +
                text +
                target.value.substring(endPos, target.value.length))
            target.selectionStart = startPos + text.length;
            target.selectionEnd = startPos + text.length;
        } else {
            setValue(text)
        }

        autosize.update(target)
    }, [setValue])

    const replaceTextValue = useCallback((textForm, textTo) => {
        let target = refs.current.finalRefs.textarea.current
        let tempValue = target.value

        setValue(tempValue.replace(textForm, textTo))
        autosize.update(target)
    }, [setValue])

    const pasteHandler = e => {
        if (e.clipboardData.types.includes('text/html')) {
            let data = new DataTransfer(e.clipboardData);
            let turndownService = new TurndownService({
                headingStyle: 'atx',
                emDelimiter: '*',
                bulletListMarker: '-',
            })
            let turndownPluginGfm = require('turndown-plugin-gfm')
            turndownService.use([turndownPluginGfm.gfm, turndownPluginGfm.tables, turndownPluginGfm.strikethrough])
            addTextValue(turndownService.turndown(data.getHTML()))
            e.preventDefault()
        } else if (e.clipboardData.types.includes('Files') && uploadPaste !== undefined) {
            let data = new DataTransfer(e.clipboardData);
            let files = data.getFiles()
            if (files[0] && !loadingUpload) {
                setLoadingUpload(true)
                let tempTextLoadingUpload = '![upload](upload) '
                addTextValue(tempTextLoadingUpload)
                uploadPaste(files[0], (success, data) => {
                    setLoadingUpload(false)
                    if (success) {
                        if (data.data.thumbnail_card_url !== null) {
                            replaceTextValue(tempTextLoadingUpload, '![' + data.data.name + '](' + data.data.url + ') ')
                        } else {
                            replaceTextValue(tempTextLoadingUpload, '[' + data.data.name + '](' + data.data.url + ') ')
                        }
                    }else{
                        replaceTextValue(tempTextLoadingUpload, '')
                    }
                })
            }
        }
        autosize.update(e.currentTarget)
    }
 
    return (
        <ReactMde
            value={value}
            onChange={setValue}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={markdown =>
                Promise.resolve(marked(markdown))
            }
            loadSuggestions={loadSuggestions}
            classes={{
                textArea: 'form-control',
                grip: 'd-none',
                preview: previewMdClass ? previewMdClass : 'markdown-preview'
            }}
            childProps={{
                textArea: {
                    placeholder: placeholder,
                    style: { minHeight: minEditorHeight !== undefined ? minEditorHeight : 100 },
                    onPaste: pasteHandler
                }
            }}
            toolbarCommands={[
                ["bold", "italic", "strikethrough"],
                ["link", "image"],
                ["unordered-list", "ordered-list", "checked-list"]
            ]}
            suggestionTriggerCharacters={['@', '#', '!', ':']}
            minPreviewHeight={'auto'}
            ref={refs}
        />
    );
}
 
export default MdEditor;