import React, { useRef } from 'react';
import Moment from 'react-moment';
import marked from 'marked';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AttachmentContrainer from '../../container/AttachmentContainer';
import MdEditor from '../Layout/MdEditor';

const DocumentDetail = (props) => {
    let { 
        isCreate, 
        documentItem, 
        permission, 
        loadSuggestions, 
        focus, 
        formData, 
        loadingSubmit, 
        handler, 
        changeUploadHandler,
        loadingUpload, 
        uploadPaste,
    } = props

    const inputAttechmentRef = useRef(null)
    
    return (
        <div className={`px-3 flex-full${isCreate ? ' d-none': ''}`} style={{backgroundColor: "#fafafa"}}>
            <div className="document-container my-4">
                {focus === 'title' ? (
                    <form
                        onSubmit={handler.submitTitleHandler}
                        className="form-group w-100">
                        <input
                            placeholder="Discussion Subject"
                            className="form-control"
                            value={formData.title === undefined ? documentItem.title : formData.title}
                            onChange={handler.changeTitleHandler}
                            required
                            type="text" />
                        <div className="text-right mt-1">
                            {loadingSubmit ? (
                                <small className="text-muted mr-2">Loading...</small>
                            ) : (
                                    <a onClick={handler.submitTitleHandler} className="mr-2" href="/">
                                        <FontAwesomeIcon icon="save" fixedWidth />
                                    </a>
                                )}
                            <a
                                data-key=""
                                onClick={handler.setFocusHandler}
                                className="text-muted" href="/">
                                <FontAwesomeIcon icon="times" fixedWidth />
                            </a>
                        </div>
                    </form>
                ) : (
                        permission.canEditTitle && documentItem.wikiLinkId !== undefined  ? (
                            <h2 onDoubleClick={handler.setFocusHandler} data-key="title">{documentItem.title}</h2>
                        ) : (
                                <h2>{documentItem.title}</h2>
                            )
                    )}
                <div className="mb-2 d-flex justify-content-between align-items-center">
                    <small className="text-muted">Diperbarui: <Moment format="D MMM YYYY">{documentItem.modified_date}</Moment></small>
                    <small className="text-muted">Dibuat: <Moment format="D MMM YYYY">{documentItem.created_date}</Moment></small>
                </div>
                <div className="document-paper">
                    {focus === 'content' ? (
                        <form
                            onSubmit={handler.submitContentHandler}
                            className="form-group">
                            <MdEditor
                                placeholder="Content Document"
                                setValue={handler.changeContentHandler}
                                loadSuggestions={loadSuggestions}
                                uploadPaste={uploadPaste}
                                previewMdClass="markdown-body"
                                value={formData.content === undefined ? documentItem.content : formData.content} />
                            <div className="mt-1 text-right">
                                {loadingSubmit ? (
                                    <small className="text-muted mr-2">Loading...</small>
                                ) : (
                                        <a onClick={handler.submitContentHandler} className="mr-2" href="/">
                                            <FontAwesomeIcon icon="save" fixedWidth />
                                        </a>
                                    )}
                                <a
                                    data-key=""
                                    onClick={handler.setFocusHandler}
                                    className="text-muted"
                                    href="/">
                                    <FontAwesomeIcon icon="times" fixedWidth />
                                </a>
                            </div>
                        </form>
                    ) : (
                            <div onDoubleClick={handler.setFocusHandler} data-key="content" className="markdown-body">
                                {documentItem.content ? (
                                    <div dangerouslySetInnerHTML={{ __html: marked(documentItem.content) }} />
                                ) : (
                                        <div className="pb-3 text-muted">Empty content document</div>
                                    )}
                            </div>
                        )}
                </div>

                {/* SECTION Action Buttons */}
                <div className="small text-muted mb-2">
                    {loadingUpload > 0 ? <span className="mr-3">Loading...</span> : (
                        <>
                            <a href="/" onClick={e => {
                                e.preventDefault()
                                inputAttechmentRef.current.click()
                            }} className="text-muted text-decoration-none mr-3">
                                <FontAwesomeIcon icon="paperclip" className="mr-1" fixedWidth />Add Attachment
                                </a>
                            <div className="d-none">
                                <input
                                    onChange={changeUploadHandler}
                                    type="file" multiple
                                    id="input-attechment"
                                    ref={inputAttechmentRef} />
                            </div>
                        </>
                    )}
                    <div className="dropdown mr-3" style={{ display: "inline-block" }}>
                        <a
                            data-toggle="dropdown"
                            className="text-muted"
                            href="/"><FontAwesomeIcon icon="ellipsis-h" className="mr-1" />More</a>
                        <div className="dropdown-menu mt-2">
                            {permission.canEditTitle && documentItem.wikiLinkId !== undefined && (
                                <a onClick={handler.setFocusHandler} data-key="title" className="dropdown-item" href="/">Edit Title</a>
                            )}
                            <a onClick={handler.setFocusHandler} data-key="content" className="dropdown-item" href="/">Edit Content</a>
                        </div>
                    </div>
                </div>

                <div>
                    <AttachmentContrainer />
                </div>
            </div>
        </div>
    );
}
 
export default DocumentDetail;