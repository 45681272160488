const suggestionIcons = (text) => {
    return new Promise((accept) => {
        setTimeout(() => {
            let icons = require('./emoji_simple.json')
            let sugestions = icons.filter(icon => {
                let caption = icon.aliases.join()
                return caption.includes(text)
            }).map(icon => {
                return {
                    preview: icon.emoji + ' ' + icon.aliases.join(', '),
                    value: icon.emoji
                }
            })
            accept(sugestions);
        }, 250);
    });
}
 
export default suggestionIcons;