import axios from 'axios';

let sourceLoadActifity = null;

export const loadActifity = (authHandler, dataType, params, callback) => {
    if(sourceLoadActifity !== null) {
        sourceLoadActifity.cancel()
    }
    sourceLoadActifity = axios.CancelToken.source()
    axios
        .get('history/' + dataType.singular + '/' + dataType.id, {
            params: params,
            headers: {...authHandler.getHeaders, 'x-disable-pagination': 1},
            cancelToken: sourceLoadActifity.token
        })
        .then(response => {
            sourceLoadActifity = null
            callback(true, { type: 'LOAD_ACTIFITY', data: response.data })
        })
        .catch(error => {
            callback(false, { error })
        });
}

export const inputActifity = (dataType, authHandler, payload, callback) => {
    axios
        .patch(dataType.plural + '/' + dataType.id, { ...payload, version: dataType.version}, {
            headers: authHandler.getHeaders
        })
        .then(response => {
            callback(true, response.data)
        })
        .catch(error => {
            callback(false, { error })
        });
}