import React, { useContext } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MainContext } from '../../../contexts/MainContext';
import { AppContext } from '../../../contexts/AppContext';

import { eventTypeLabel } from '../../../configs/content';

const Navbar = ({ leftNav, rightNav }) => {
    const { auth, authHandler } = useContext(AppContext);
    const { minimized, setMinimized, notification, readNotification } = useContext(MainContext);

    let notification_count = notification.length;
    // notification_count = 52; // untuk testing
    return (
        <nav className="navbar navbar-expand-sm navbar-light bg-white border-bottom" style={{whiteSpace: 'nowrap'}}>
            <div className="container-fluid">
                <div role="button" className="ml-2 mr-2">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        role="img"
                        focusable="false"
                        className={`hamburger_svg${minimized ? ' minimized' : ''}`}
                        onClick={() => setMinimized()}
                        style={{ zIndex: 90, position: "relative", cursor: "pointer" }}>
                        <title>Menu</title>
                        <path stroke="currentColor" strokeWidth="1" d="M0 9h18" />
                        <path stroke="currentColor" strokeWidth="1" d="M0 15h18" />
                        <path stroke="currentColor" strokeWidth="1" d="M0 21h18" />
                    </svg>
                </div>
                {leftNav}
                <ul className="navbar-nav navbar-right">
                    {rightNav}
                    <li className="nav-item dropdown">
                        <a className={`nav-link nav-link-icon`} href="/" role="button" data-toggle="dropdown" aria-expanded="false">
                            <span className={notification_count > 0 ? "shake-it" : ''}>
                                <FontAwesomeIcon icon={['far', 'bell']} />
                            </span>
                            {notification_count > 0 && (
                                <span className="badge badge-primary">
                                    {notification_count < 100 ? notification_count : "99+"}
                                </span>
                                // <span className={'badge-dot'}></span>
                            )}
                        </a>
                        {notification_count === 0 ? (
                            <div className="dropdown-menu dropdown-menu-right shadow-sm">
                                <div className="dropdown-body">
                                    <span className="dropdown-item disabled text-muted">Empty Notifications</span>
                                </div>
                            </div>
                        ) : (
                            <div className="dropdown-menu dropdown-menu-right shadow-sm py-0" style={{ minWidth: 450}}>
                                <div className="border-bottom">
                                    <div className="dropdown-item disabled d-flex justify-content-between">
                                        <span className="text-muted mr-3">You have {notification_count} new notifications</span>
                                        {/* <div>
                                            <a href="/" className="mr-2">Dismiss</a>
                                            <a href="/" className="">View All</a>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="dropdown-body">
                                    {notification.map((item, i) => (
                                        <Link className="dropdown-item border-bottom" key={i} to="/notifications" onClick={e => {
                                            e.preventDefault()
                                            readNotification(item)
                                        }}>
                                            <div className="media py-2">
                                                <div className="mr-2">
                                                    <UserAvatar size="40" name={item.data.user.name} src={item.data.user.photo} />
                                                </div>
                                                <div className="media-body pl-1">
                                                    <div className="d-flex justify-content-between align-items-center mb-1">
                                                        <div>
                                                            <small>{item.data.user.name}&nbsp;</small>
                                                            <small className="text-muted mr-3">{eventTypeLabel[item.event_type]}</small>
                                                        </div>

                                                        <small className="text-muted text-nowrap"><Moment fromNow>{item.created}</Moment></small>
                                                    </div>

                                                    <strong className="text-nowrap">{item.data.project.name}</strong>
                                                    <span>{item.data.obj?.subject ? ': ' + item.data.obj?.subject : ''}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        )}
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link d-flex" href="/" role="button" data-toggle="dropdown">
                            <div className="navbar-avatar">
                                <UserAvatar className="UserAvatar--smaller" size="32" name={auth.full_name_display} src={auth.photo} />
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right shadow-sm">
                            <a className="dropdown-item" href="/">
                                <div className="media py-2">
                                    <div className="mr-2">
                                        <UserAvatar size="40" name={auth.full_name_display} src={auth.photo} />
                                    </div>
                                    <div className="media-body pl-1 pr-3">
                                        <span className="text-nowrap">{auth.full_name_display}</span>
                                        <div className="mt-1">
                                            {/* <small className="text-muted">{auth.role_name}</small> */}
                                            <small className="text-muted">@{auth.username}</small>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <div className="dropdown-divider"></div>
                            <Link to="/" className="dropdown-item">Profile</Link>
                            <Link to="/" onClick={authHandler.logout} className="dropdown-item">Logout</Link>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;