import React, { createContext, useState, useCallback, useContext } from 'react'

import { AppContext } from './AppContext';
import { ChannelContext } from './ChannelContext';
import { DiscussionContext } from './DiscussionContext';
import { getDiscussion } from '../actions/discussion';

export const DiscussionDetailContext = createContext();

const DiscussionDetailContextProvider = ({ children }) => {

    const { authHandler } = useContext(AppContext)
    const { channel } = useContext(ChannelContext)
    const { discussionDispatch, discussionRef } = useContext(DiscussionContext)

    const [discussionItem, setDiscussionItem] = useState({});

    const reloadDiscussionDetail = useCallback((callback) => {
        getDiscussion(authHandler, {
            // order_by: 'status',
            project: channel.id,
            ref: discussionRef,
        }, (success, data, dataDispatch) => {
            if (success) {
                setDiscussionItem(data)
                discussionDispatch(dataDispatch)
            }
            callback(success, data)
        })
    }, [authHandler, channel.id, discussionRef, setDiscussionItem, discussionDispatch])

    return (
        <DiscussionDetailContext.Provider value={{ discussionItem, setDiscussionItem, reloadDiscussionDetail }}>
            {children}
        </DiscussionDetailContext.Provider>
    );
}

export default DiscussionDetailContextProvider;