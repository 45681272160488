import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { DiscussionContext } from '../contexts/DiscussionContext';

import Discussion from '../components/Discussion';

const DiscussionContainer = ({ match }) => {
    const { setIsCreate, setDiscussionRef } = useContext(DiscussionContext)

    useEffect(() => {
        setIsCreate(false)
        setDiscussionRef(match.params.discussionRef)
    }, [match.params.discussionRef, setIsCreate, setDiscussionRef])

    return <Discussion />
}

export default withRouter(DiscussionContainer);