import React, { useContext, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import { AppContext } from '../contexts/AppContext';
import { ChannelContext } from '../contexts/ChannelContext';
import { AttechmentContext } from '../contexts/AttechmentContext';
import { inputActifity } from '../actions/actifity';
import suggestionIcons from '../utils/suggestionIcons';

import CommentCreate from '../components/Comment/CommentCreate';

const CommentCreateContainer = () => {

    const { auth, authHandler } = useContext(AppContext);
    const { setActifityType, actifityType, loadSugestionMembers, loadSugestionRefs } = useContext(ChannelContext);
    const { loadSugestionImages, uploadPaste } = useContext(AttechmentContext);

    const [loadingForm, setLoadingForm] = useState(false)

    const [comment, setCommnet] = useState('');

    const submitCallback = useCallback((success, data) => {
        if (success) {
            toast.success('Success add comment.')
            setCommnet('')
            setActifityType({ ...actifityType, version: data.version})
        } else {
            toast.error(data.error.response.data._error_message)
        }
        setLoadingForm(false)
    }, [setActifityType, actifityType])

    const handlerSubmit = (e) => {
        e.preventDefault();
        if (!loadingForm) {
            setLoadingForm(true)
            inputActifity(actifityType, authHandler, {
                comment: comment,
            }, submitCallback)
        }
    }

    const loadSuggestions = (text, triggeredBy) => {
        if (triggeredBy === '@') {
            return loadSugestionMembers(text)
        } else if (triggeredBy === '#') {
            return loadSugestionRefs(text)
        } else if (triggeredBy === ':') {
            return suggestionIcons(text)
        } else if (triggeredBy === '!') {
            return loadSugestionImages(text)
        }
    }

    return <CommentCreate 
        userData={{ photo: auth.photo, name: auth.full_name_display }}
        comment={comment}
        setCommnet={setCommnet} 
        loadSuggestions={loadSuggestions}
        loadingForm={loadingForm}
        uploadPaste={(file, callback) => { uploadPaste(file, callback, true) }}
        handlerSubmit={handlerSubmit} />
}

export default CommentCreateContainer;