import React, { useContext, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../contexts/AppContext';
import { ChannelContext } from '../contexts/ChannelContext';
import { createDiscussion } from '../actions/discussion';
import { DiscussionContext } from '../contexts/DiscussionContext';
import suggestionIcons from '../utils/suggestionIcons';

import DiscussionCreate from '../components/Discussion/DiscussionCreate';

const DiscussionCreateContainer = () => {
    const history = useHistory()
    const { auth, authHandler } = useContext(AppContext)
    const { isCreate, setIsCreate, reloadDiscussion, setDiscussionRef } = useContext(DiscussionContext)
    const { channel, loadSugestionMembers, loadSugestionRefs } = useContext(ChannelContext)

    let {
        default_issue_status,
        default_issue_type,
        default_priority,
        default_severity,
        issue_statuses,
        issue_types,
        // priorities,
        // severities,
    } = channel

    let defaultFormData = {
        project: channel.id,
        subject: '',
        description: '',
        tags: [],
        watchers: channel.members.map((member) => member.id),
        status: default_issue_status,
        type: default_issue_type,
        severity: default_severity,
        priority: default_priority,
    }
    const [formData, setFormData] = useState(defaultFormData)
    const [tempTag, setTempTag] = useState({})
    const [loadinForm, setLoadinForm] = useState(false)

    const handlerChangeInput = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    
    const handlerChangeDescription = value => {
        setFormData({ ...formData, description: value })
    }
    
    const changeTag = e => {
        setTempTag({ ...tempTag, [e.currentTarget.dataset.key]: e.target.value})
    }
    
    const addTag = e => {
        e.preventDefault();
        setTempTag({})
        setFormData({ ...formData, tags: [...formData.tags, [e.currentTarget.dataset.name, e.currentTarget.dataset.color]] })
    }
    
    const removeTag = e => {
        e.preventDefault();
        let tempTags = formData.tags
        tempTags.splice(e.currentTarget.dataset.value, 1)
        setFormData({ ...formData, tags: tempTags })
    }

    const successCreateCallback = useCallback((refId) => {
        setFormData(defaultFormData)
        setDiscussionRef(refId)
        setIsCreate(false)
        history.push('/channels/' + channel.slug + '/discussions/' + refId)
        reloadDiscussion(() => {})
    }, [channel.slug, defaultFormData, history, reloadDiscussion, setIsCreate, setDiscussionRef])

    const submitCallback = useCallback((success, data) => {
        setLoadinForm(false)
        if (success) {
            toast.success('Success create discussion.')
            successCreateCallback(data.ref)
        } else {
            toast.error(data.error.response.data._error_message)
        }
    }, [successCreateCallback])

    const handlerSubmit = e => {
        e.preventDefault();
        if (!loadinForm) {
            setLoadinForm(true)
            createDiscussion(authHandler, formData, submitCallback)
        }
    }

    const closeHandler = e => {
        e.preventDefault();
        if (!loadinForm) {
            setIsCreate(false)
        }
    }

    const loadSuggestions = (text, triggeredBy) => {
        if (triggeredBy === '@') {
            return loadSugestionMembers(text)
        } else if (triggeredBy === '#') {
            return loadSugestionRefs(text)
        } else if (triggeredBy === ':') {
            return suggestionIcons(text)
        }
    }

    return <DiscussionCreate 
        isCreate={isCreate}
        closeHandler={closeHandler}
        loadinForm={loadinForm}
        issue_statuses={issue_statuses}
        issue_types={issue_types}
        formData={formData}
        handlerChangeInput={handlerChangeInput}
        handlerChangeDescription={handlerChangeDescription}
        handlerSubmit={handlerSubmit}
        loadSuggestions={loadSuggestions}
        tempTag={tempTag}
        changeTag={changeTag}
        addTag={addTag}
        removeTag={removeTag}
        tags_colors={channel.tags_colors}
        userData={{ photo: auth.photo, name: auth.full_name_display }} />
}

export default DiscussionCreateContainer;