import { library } from '@fortawesome/fontawesome-svg-core'

import { 
    faBars, 
    faUser, 
    // faBell, 
    faHashtag, 
    faPlus, 
    faSearch, 
    faAngleDown, 
    faCircle, 
    faBookmark, 
    faUserAstronaut, 
    faSave, 
    faTimes, 
    faPencilAlt, 
    faSquare, 
    faPaperclip, 
    faSpinner, 
    faEllipsisV, 
    faEllipsisH,
    faHome,
    faAlignCenter,
    faTag,
    faToggleOff,
    faToggleOn,
    faTh,
    faEject,
    faCheck,
    faExternalLinkAlt,
    faInbox,
    faStepForward,
    faTasks
} from '@fortawesome/free-solid-svg-icons'

import { 
    far,
    faBell, 
    faArrowAltCircleDown
} from '@fortawesome/free-regular-svg-icons'

library.add(
    far,
    faBars, 
    faUser, 
    faBell, 
    faHashtag, 
    faPlus, 
    faSearch, 
    faAngleDown, 
    faCircle, 
    faBookmark, 
    faUserAstronaut, 
    faSave, 
    faTimes, 
    faPencilAlt, 
    faSquare, 
    faPaperclip, 
    faSpinner, 
    faEllipsisV, 
    faEllipsisH,
    faHome,
    faAlignCenter,
    faTag,
    faToggleOff,
    faToggleOn,
    faTh,
    faEject,
    faCheck,
    faExternalLinkAlt,
    faInbox,
    faStepForward,
    faTasks,
    faArrowAltCircleDown
)
