import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ChannelContext } from '../../../contexts/ChannelContext';
import UserAvatar from 'react-user-avatar';

const RightNavChannel = () => {
    const { channel } = useContext(ChannelContext);

    const [memberDisplay, setMemberDisplay] = useState([])
    const [loated, setLoated] = useState(false)

    useEffect(() => {
        let temp = []
        channel.members.forEach((item, i) => {
            if(i < 2) {
                temp.unshift(item)
            }
        })
        setMemberDisplay(temp)
        setLoated(true)
    }, [channel.members])

    if (!loated) {
        return <></>
    }

    return (
        <>
            <li className="nav-item d-flex align-items-center">
                <div className="form-search d-none d-sm-block mr-1">
                    <input className="form-control rounded-pill" id="search" type="search" placeholder="Search" aria-label="Search" />
                    <label htmlFor="search" className="search-label">
                        <FontAwesomeIcon icon="search" />
                    </label>
                </div>
            </li>
            <li className="nav-item dropdown">
                <a className="nav-link" href="/" role="button" data-toggle="dropdown" aria-expanded="false">
                    <ul className="navbar-avatar-group">
                        {channel.members.length > 2 && (
                            <li>
                                <div className="navbar-avatar">{channel.members.length - 2}+</div>
                            </li>
                        )}
                        {memberDisplay.map((member, i) => (
                            <li key={i}>
                                <UserAvatar size="32" className="UserAvatar--smaller" name={member.full_name_display} src={member.photo} />
                            </li>
                        ))}
                    </ul>
                </a>
                <div className="dropdown-menu dropdown-menu-right shadow-sm py-0">
                    <div className="dropdown-body">
                        {channel.members.map((member, i) => (
                            <a className="dropdown-item" href="/" key={i} onClick={e => {
                                e.preventDefault()
                                navigator.clipboard.writeText('@' + member.username)
                            }}>
                                <div className="media py-1">
                                    <div className="mr-2">
                                        <UserAvatar size="32" className="UserAvatar--smaller" name={member.full_name_display} src={member.photo} />
                                    </div>
                                    <div className="media-body">
                                        <span className="text-nowrap">{member.full_name_display}</span>
                                        <div>
                                            {/* <small className="text-muted">{member.role_name}</small> */}
                                            <small className="text-muted">@{member.username}</small>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </li>
        </>
    );
}
 
export default RightNavChannel;