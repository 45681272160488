const authReducer = (state, action) => {
    switch (action.type) {
        case 'AUTH_LOGIN':
            return action.data
        case 'AUTH_LOGOUT':
            return {}
        default:
            return state;
    }
}

export default authReducer;