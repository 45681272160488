import axios from 'axios';
import { arrayColumn } from "../utils/helper";
import { hamePageTitle } from '../configs/content';
let sourceLoadDocument = null;

export const loadDocument = (authHandler, params, callback) => {
    if(sourceLoadDocument !== null) {
        sourceLoadDocument.cancel()
    }
    sourceLoadDocument = axios.CancelToken.source()

    
    const compare = (a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();
    
        let comparison = 0
        if (titleA > titleB) {
            comparison = 1
        } else if (titleA < titleB) {
            comparison = -1
        }
        return comparison
    }


    
    Promise.all([
        axios.get('wiki-links', {
            params: params,
            headers: authHandler.getHeaders,
            cancelToken: sourceLoadDocument.token
        }),
        axios.get('wiki', {
            params: params,
            headers: authHandler.getHeaders,
            cancelToken: sourceLoadDocument.token
        })
    ]).then(axios.spread((...responses) => {
        let wikiLinks = responses[0].data
        let wikiPages = arrayColumn(responses[1].data, 'slug')
        let response = []
        if (wikiPages['home'] !== undefined) {
            response.push({
                title: hamePageTitle, 
                ...wikiPages['home']
            })
        }
        
        wikiLinks = wikiLinks.sort(compare)

        wikiLinks.forEach(item => {
            if (item.href in wikiPages) {
                response.push({ ...item, ...wikiPages[item.href], wikiLinkId: item.id })
            }
        })

        sourceLoadDocument = null
        callback(true, { type: 'LOAD_DOCUMENT', data: response })
    })).catch(error => {
        callback(false, { error })
    });
}

export const createDocument = (authHandler, payload, callback) => {
    let { id, title, content } = payload
    axios
        .post('wiki-links', {
            project: id,
            title: title,
        }, {
            headers: authHandler.getHeaders
        })
        .then(response => {
            let wikiLink = response.data
            axios.get('wiki/by_slug', {
                    params: {
                        project: id, 
                    slug: wikiLink.href
                    },
                    headers: authHandler.getHeaders
                })
                .then(response => {
                    let wikiPage = response.data
                    axios
                        .patch('wiki/' + wikiPage.id, {
                            content: content,
                            version: wikiPage.version
                        }, {
                            headers: authHandler.getHeaders
                        })
                        .then(response => {
                            wikiPage = response.data
                            callback(true, { ...wikiLink, ...wikiPage, wikiLinkId: wikiLink.id})
                        })
                        .catch(error => {
                            callback(false, { error })
                        });
                })
                .catch(error => {
                    callback(false, { error })
                });
        })
        .catch(error => {
            callback(false, { error })
        });
}

export const editTitleDocument = (authHandler, params, payload, callback) => {
    axios
        .patch('wiki-links/' + params.id, payload, {
            headers: authHandler.getHeaders
        })
        .then(response => {
            callback(true, response.data)
        })
        .catch(error => {
            callback(false, { error })
        });

}

export const editContentDocument = (authHandler, params, payload, callback) => {
    axios
        .patch('wiki/' + params.id, payload, {
            headers: authHandler.getHeaders
        })
        .then(response => {
            callback(true, response.data)
        })
        .catch(error => {
            callback(false, { error })
        });

}