import React from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import marked from 'marked';

import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'

import { apiUrl } from './configs/api'
import { toastConfig } from './configs/app'
import * as serviceWorker from './serviceWorker';

import "./assets/vendors/react-mde/scss/react-mde-all.scss";

import './assets/scss/bootstrap.scss';
import './assets/scss/style.scss';
import './assets/css/customReactToastify.css';

import './utils/fontawesome';
import App from './components/App';

import 'moment/locale/id';

var renderer = new marked.Renderer();
renderer.link = function(href, title, text) {
    var link = marked.Renderer.prototype.link.apply(this, arguments);
    let attrUrl = ':target=blank'
    if (href.includes(attrUrl)){
        return link.replace('<a href="' + href + '"', '<a href="' + href.replace(attrUrl, '')+'" target="_blank"')
    }
    return link;
};

marked.setOptions({
    renderer: renderer,
    pedantic: false,
    gfm: true,
    breaks: true,
    sanitize: false,
    smartLists: true,
    smartypants: true,
    xhtml: false
});

toast.configure(toastConfig)
axios.defaults.baseURL = apiUrl;

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
