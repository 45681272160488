import React, { useContext, useEffect } from 'react';
import Navbar from '../Layout/Navbar';
import { ChannelContext } from '../../contexts/ChannelContext';
import Blank from '../Layout/Blank';

const Dashboard = () => {
    const { setChannel } = useContext(ChannelContext);

    useEffect(() => {
        setChannel({})
    }, [setChannel])

    return (
        <>
            <Navbar />
            <Blank />
        </>
    );
}
 
export default Dashboard;