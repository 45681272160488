import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors } from '../../configs/app';

import Media from '../Layout/Media';
import MdEditor from '../Layout/MdEditor';

const DiscussionCreate = (props) => {
    let { 
        isCreate,
        closeHandler, 
        loadinForm, 
        issue_statuses, 
        issue_types, 
        formData, 
        handlerChangeInput, 
        handlerChangeDescription, 
        handlerSubmit, 
        loadSuggestions,
        tags_colors, 
        tempTag, changeTag, addTag, removeTag,
        userData, 
    } = props

    const [showColorOptions, setShowColorOptions] = React.useState(false)
    const tempRef = React.useRef(null)
    
    let filtered_tags = Object.entries(tags_colors).filter(tag => {
        return tag[0].includes(tempTag.name === undefined ? '' : tempTag.name)
    })

    return (
        <form 
            onSubmit={handlerSubmit}
            className={`card border-0 flex-full${isCreate ? '' : ' d-none'}`}>
            <div className="card-header py-3">
                <Media userData={userData}>
                    <div className="form-group">
                        <input
                            value={formData.subject}
                            onChange={handlerChangeInput}
                            name="subject"
                            placeholder="Discussion Subject"
                            className="form-control"
                            required
                            type="text"/>
                    </div>
                    <div className="form-group">
                        <div className="form-inline">
                            <select
                                onChange={handlerChangeInput}
                                value={formData.type}
                                name="type"
                                className="custom-select custom-select-sm mr-2">
                                {issue_types.map((type, i) => (
                                    <option
                                        key={i}
                                        value={type.id}
                                        style={{ color: type.color }}>&#11044;&nbsp;&nbsp;{type.name}</option>
                                ))}
                            </select>
                            <select
                                onChange={handlerChangeInput}
                                value={formData.status}
                                name="status"
                                className="custom-select custom-select-sm mr-2">
                                {issue_statuses.map((status, i) => (
                                    <option 
                                        key={i}
                                        value={status.id} 
                                        style={{ color: status.color }}>&#11044;&nbsp;&nbsp;{status.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <MdEditor 
                            placeholder="Discussion Description"
                            setValue={handlerChangeDescription}
                            loadSuggestions={loadSuggestions}
                            value={formData.description}/>
                    </div>
                    <div className="form-group">
                        <ul className="list-inline mb-0 d-inline-block">
                            {formData.tags.map((item, i) => (
                                <li className="list-inline-item mr-1 mb-1" key={i}>
                                    <a href="/" className="btn btn-sm" 
                                        onClick={removeTag}
                                        data-value={i}
                                        style={{ border: "1px solid " + item[1], color: item[1]}}>
                                        {item[0]}
                                        <FontAwesomeIcon icon="times" size="sm" className="ml-1 text-muted"/>
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <div className="dropdown mr-3 mb-1" style={{ display: "inline-block" }}>
                            <button type="button" ref={tempRef} data-toggle="dropdown" className="btn btn-sm btn-default">Add Label</button>
                            <div className="dropdown-menu mt-2">
                                <div className="px-2 mb-2">
                                    <input
                                        value={tempTag.name === undefined ? '' : tempTag.name}
                                        onChange={changeTag}
                                        onKeyUp={changeTag}
                                        data-key="name"
                                        type="text"
                                        placeholder="Search or create..."
                                        className="form-control form-control-sm mr-2" />
                                </div>
                                <div style={{ maxHeight: '180px', overflow: 'auto' }}>
                                    {filtered_tags.map((tag, i) =>
                                        !Object.fromEntries(formData.tags).hasOwnProperty(tag[0]) ? (
                                            <a
                                                key={i}
                                                data-name={tag[0]}
                                                data-color={tag[1]}
                                                onClick={addTag}
                                                href="/" className="dropdown-item px-2" >
                                                <FontAwesomeIcon icon="square" fixedWidth
                                                    className="mr-2"
                                                    style={{ color: tag[1] }} />
                                                <small>
                                                    <span>{tag[0]}</span>
                                                </small>
                                            </a>
                                        ) : (
                                                <span key={i} className="dropdown-item px-2 text-muted">
                                                    <FontAwesomeIcon icon="check" fixedWidth
                                                        className="mr-2"
                                                        style={{ color: tag[1] }} />
                                                    <small>
                                                        <span>{tag[0]}</span>
                                                    </small>
                                                </span>
                                            )
                                    )}
                                </div>

                                {tempTag.name && !filtered_tags.length && (
                                    <>
                                        <div className="d-flex px-2">
                                            <button
                                                type="button"
                                                onClick={(e) => {
                                                    tempRef.current.click()
                                                    setShowColorOptions(!showColorOptions)
                                                }}
                                                className="btn btn-default btn-sm px-0 mr-2 float-left"
                                                style={{
                                                    width: 38,
                                                    background: tempTag.color === undefined ? '#CD1C86' : tempTag.color
                                                }} />
                                            <button
                                                data-name={tempTag.name}
                                                data-color={tempTag.color === undefined ? '#CD1C86' : tempTag.color}
                                                onClick={addTag}
                                                className="btn btn-default btn-sm btn-block" >Create "{tempTag.name}"</button>
                                        </div>

                                        <div className="px-2 mt-2">
                                            {showColorOptions && Object.keys(colors).map((key) => (
                                                <button type="button"
                                                    key={key}
                                                    onClick={(e) => {
                                                        tempRef.current.click()
                                                        changeTag(e)
                                                        setShowColorOptions(false)
                                                    }}
                                                    data-key="color"
                                                    value={colors[key]}
                                                    className="btn btn-default p-0 mr-1 mb-1"
                                                    style={{ width: 20, height: 20, background: colors[key] }} />
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Media>
            </div>
            <div className="card-body text-right">
                <button 
                    onClick={closeHandler}
                    type="button"
                    className="btn btn-outline-secondary rounded-pill mr-1">Close</button>
                {loadinForm ? (
                    <button className="btn btn-primary rounded-pill disabled" disabled>Loading...</button>
                ) : (
                    <button className="btn btn-primary rounded-pill">Post Discussion</button>
                )}
            </div>
        </form>
    );
}

export default DiscussionCreate;