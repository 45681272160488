import axios from 'axios';

let sourceLoadAttachment = null;

export const resetAttectment = () => {
    return { type: 'RESET_ATTECHMENT' }
}

export const loadAttectment = (authHandler, dataType, params, callback) => {
    if(sourceLoadAttachment !== null) {
        sourceLoadAttachment.cancel()
    }
    sourceLoadAttachment = axios.CancelToken.source()
    axios
        .get(dataType.plural + '/attachments', {
            params: params,
            headers: { ...authHandler.getHeaders, 'x-disable-pagination': 1 },
            cancelToken: sourceLoadAttachment.token
        })
        .then(response => {
            sourceLoadAttachment = null
            callback(true, { type: 'LOAD_ATTECHMENT', data: response.data })
        })
        .catch(error => {
            callback(false, { error })
        });
}

export const inputAttectment = (dataType, authHandler, payload, callback) => {
    axios
        .post(dataType.plural + '/attachments', payload, {
            headers: { ...authHandler.getHeaders, 'Content-Type': 'multipart/form-data'}
        })
        .then(response => {
            callback(true, { type: 'INSERT_ATTECHMENT', data: response.data })
        })
        .catch(error => {
            callback(false, { error })
        });
}
