import React from 'react';
import { Link, matchPath } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SidenavItem = ({ type, path, icon, label, badge, routePath }) => {
    return type === 'divider' ? (
        <div className={label !== undefined ? 'nav-divider-text' : 'nav-divider'}>{label !== undefined && label}</div>
    ) : (
        <li className={`nav-item${matchPath(routePath, { path: path }) ? ' active' : ''}`}>
            <Link to={path} className="nav-link">
                <FontAwesomeIcon icon={ icon } className="mr-3" fixedWidth />
                <span>{ label }</span>
                {badge !== 0 && <span className="badge badge-primary">{ badge }</span>}
            </Link>
        </li>
    );
}
 
export default SidenavItem;