import React from 'react';

import SimpleLayout from './Layout/SimpleLayout';

const Login = ({ loadingForm, handlerChangeInput, handlerSubmit }) => {
    return (
        <SimpleLayout>
            <div className="container content">
                <div className="content-body">
                    <div className="row py-3">
                        <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 mx-auto">
                            <div className="content-header text-center">
                                <h1 className="content-title text-secondary">Logo Here</h1>
                            </div>
                            <form 
                                onSubmit={handlerSubmit}
                                className="card shadow-sm mb-3">
                                <div className="card-body py-4">
                                    <h2 className="card-title mb-3">Login Sek Le ...</h2>
                                    <div className="form-group">
                                        <label htmlFor="username">Username</label>
                                        <input 
                                            onChange={handlerChangeInput}
                                            name="username"
                                            type="text" 
                                            className="form-control" 
                                            id="username" 
                                            placeholder="Username" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            onChange={handlerChangeInput}
                                            name="password"
                                            type="password" 
                                            className="form-control" 
                                            id="password" 
                                            placeholder="Password" />
                                    </div>
                                    <div className="pt-3 pb-2">
                                        {loadingForm ? (
                                            <button className="btn btn-lg btn-primary rounded-pill disabled" disabled>Loading...</button>
                                        ) : (
                                            <button className="btn btn-lg btn-primary rounded-pill">Login</button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </SimpleLayout>
    );
}

export default Login;