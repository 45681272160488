import React from 'react';
import SidenavContainer from '../../container/SidenavContainer';

const MainLayout = ({ children }) => {
    return (
        <>
            <SidenavContainer />
            <div className="body flex-full d-flex flex-column">{ children }</div>
        </>
    );
}

export default MainLayout;